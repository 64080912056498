import React, {useEffect, useState} from 'react';
import {useGlobalState, get_price} from '../../store'
import { BarChart2 } from 'react-feather'
export default function Portfolio() {
    const [portfolio, setPortfolio] = useGlobalState('portfolio')
    const [positionSummary, setPositionSummary] = useGlobalState('positionSummary')
    const [tickers, setTickers] = useGlobalState('tickers')
    const [bitmexPositions, setBitmexPositions] = useGlobalState('bitmexPositions')
    const [bybitPositions, setbybitPositions] = useGlobalState('bybitPositions')
    const [walletValue, setWalletValue] = useState('walletValue')

    const get_pnl = (position) => {
      let price = get_price(position.symbol)
      if (position.size==='Buy'){
        return (position.entryPrice - position.markPrice) * position.qty
      } else {
        return (position.markPrice - position.entryPrice) * position.qty
      }
      let pnl = (price - position.entryPrice) * (position.currentQty ? position.currentQty : position.size)
      return pnl
    }
    const updateSummary = () => {
        var longs= []
      var shorts = []
      bitmexPositions.filter(p => p.currentQty != 0).forEach(position => {
        if (position.currentQty > 0) {
          var index = longs.findIndex(element => element.symbol === position.symbol && element.exchange == 'BITMEX')
          if (index > -1) {
            let btc = (longs[index].qty/longs[index].entryPrice) + position.currentQty/position.avgEntryPrice
            longs[index].entryPrice = (longs[index].qty + position.currentQty) / btc
            longs[index].qty = (longs[index].qty + position.currentQty)
            if (position.symbol === 'XBTUSD'){

              longs[index].PnL = (((longs[index].lastPrice - longs[index].entryPrice)/longs[index].entryPrice) * longs[index].qty)/longs[index].lastPrice
            } else {
              longs[index].PnL = longs[index].PnL + (position.unrealisedPnl / 100000000)
            }
            longs[index].PnLPct = ((longs[index].lastPrice - longs[index].entryPrice)/longs[index].entryPrice) * 100
          } else {
            let PnL =0
            if (position.symbol === 'XBTUSD'){

              PnL = (((position.lastPrice - position.avgEntryPrice)/position.avgEntryPrice) * position.currentQty)/position.lastPrice
            } else {
              PnL = (position.unrealisedPnl / 100000000)
            }
            let PnLPct = ((position.lastPrice - position.avgEntryPrice)/position.avgEntryPrice) * 100
            longs.push({
              symbol: position.symbol, 
              entryPrice: position.avgEntryPrice, 
              qty: position.currentQty, 
              lastPrice: position.lastPrice,
              exchange: 'BITMEX',
              PnL: PnL,
              PnLPct: PnLPct
            })
          }
        } 
        if (position.currentQty < 0) {
          var index = shorts.findIndex(element => element.symbol === position.symbol && element.exchange == 'BITMEX')
          if (index > -1) {
            let btc = (Math.abs(shorts[index].qty)/shorts[index].entryPrice) + Math.abs(position.currentQty)/position.avgEntryPrice
            shorts[index].entryPrice = (Math.abs(shorts[index].qty) + Math.abs(position.currentQty)) / btc
            shorts[index].qty = (shorts[index].qty + position.currentQty)
            if (position.symbol === 'XBTUSD') {

              shorts[index].PnL = (((shorts[index].entryPrice - shorts[index].lastPrice)/shorts[index].lastPrice ) * (-shorts[index].qty))/shorts[index].lastPrice
            } else {
              shorts[index].PnL = shorts[index].PnL + (position.unrealisedPnl / 100000000)
            }
            shorts[index].PnLPct = ((shorts[index].entryPrice - shorts[index].lastPrice)/shorts[index].lastPrice ) * 100
          } else {
            let PnL = 0
            if (position.symbol === 'XBTUSD') {

              PnL = (((position.avgEntryPrice - position.lastPrice)/position.lastPrice ) * (-position.currentQty))/position.lastPrice
            } else {
              PnL = (position.unrealisedPnl / 100000000)
            }
            let PnLPct = ((position.avgEntryPrice - position.lastPrice)/position.lastPrice) * 100
            shorts.push({
              symbol: position.symbol, 
              entryPrice: position.avgEntryPrice, 
              qty: position.currentQty, 
              lastPrice: position.lastPrice,
              exchange: 'BITMEX',
              PnL: PnL,
              PnLPct: PnLPct
            })
          }
        }
      })
      bybitPositions.filter(p => p.size != 0).forEach(position => {
        if (position.side === 'Buy') {
          if (position.symbol == 'BTCUSD') {
            var index = longs.findIndex(element => element.symbol === 'XBTUSD')
            if (index > -1) {
              
              let btc = (longs[index].qty/longs[index].entryPrice) + position.size/position.entry_price
              longs[index].entryPrice = (longs[index].qty + position.size) / btc
              longs[index].qty = (longs[index].qty + position.size)
              longs[index].PnL = (((longs[index].lastPrice - longs[index].entryPrice)/longs[index].entryPrice) * longs[index].qty)/longs[index].lastPrice
              longs[index].PnLPct = ((longs[index].lastPrice - longs[index].entryPrice)/longs[index].entryPrice) * 100
            } else {
              let price = get_price('BTC')
              let PnL = (((price - position.entry_price)/position.entry_price) * position.size)/price
              let PnLPct = ((price - position.entry_price)/position.entry_price) * 100
              longs.push({symbol: 'XBTUSD', 
              entryPrice: +position.entry_price, 
              exchange: 'BITMEX',
              qty: position.size, 
              lastPrice: price,
              PnL: PnL,
              PnLPct: PnLPct
            })
            }
          } else if (position.symbol.includes('USDT') == false){
            let index = longs.findIndex(element => element.symbol === position.symbol && element.exchange === 'BYBIT')
            if (index > -1){
              let qty = (longs[index].qty/longs[index].entryPrice) + position.size/position.entry_price
              longs[index].entryPrice = (longs[index].qty + position.size) / qty
              longs[index].qty = (longs[index].qty + position.size)
              longs[index].PnL = (((longs[index].lastPrice - longs[index].entryPrice)/longs[index].entryPrice) * longs[index].qty)/longs[index].lastPrice
              longs[index].PnLPct = ((longs[index].lastPrice - longs[index].entryPrice)/longs[index].entryPrice) * 100

            }else{
              let price = get_price(position.symbol)
              let PnL = (((price - position.entry_price)/position.entry_price) * position.size)/price
              let PnLPct = ((price - position.entry_price)/position.entry_price) * 100
              longs.push({symbol: position.symbol, 
              entryPrice: +position.entry_price, 
              exchange: 'BYBIT',
              qty: position.size, 
              lastPrice: price,
              PnL: PnL,
              PnLPct: PnLPct
            })
            }
          } else {
            let index = longs.findIndex(element => element.symbol === position.symbol && element.exchange === 'BYBIT')
            if (index > -1){
              let price = get_price(position.symbol)
              let entryPrice = ((longs[index].qty * longs[index].entryPrice) + (position.size* position.entry_price))/(longs[index].qty + position.size)
              longs[index].entryPrice = entryPrice
              longs[index].qty = (longs[index].qty + position.size)
              longs[index].PnL = (((price - longs[index].entryPrice)/longs[index].entryPrice) * longs[index].qty)
              longs[index].PnLPct = ((price- longs[index].entryPrice)/longs[index].entryPrice) * 100
              //console.log(longs[index])
            }else{
              let price = get_price(position.symbol)
              let PnL = (((price - position.entry_price)/position.entry_price) * position.size)
              let PnLPct = ((price - position.entry_price)/position.entry_price) * 100
              let pos = {symbol: position.symbol, 
                entryPrice: +position.entry_price, 
                exchange: 'BYBIT',
                qty: position.size, 
                lastPrice: price,
                PnL: PnL,
                PnLPct: PnLPct
              }
              //console.log(pos)
              longs.push(pos)
            }
          }
        } 
        if (position.side === 'Sell') {
          if (position.symbol === 'BTCUSD'){
          var index = shorts.findIndex(element => element.symbol === 'XBTUSD')
          if (index > -1) {
            let btc = (Math.abs(shorts[index].qty)/shorts[index].entryPrice) + Math.abs(position.size)/position.entry_price
            shorts[index].entryPrice = (Math.abs(shorts[index].qty) + Math.abs(position.size)) / btc
            shorts[index].qty = (shorts[index].qty + -position.size)
            shorts[index].PnL = (((shorts[index].entryPrice - shorts[index].lastPrice)/shorts[index].lastPrice ) * (-shorts[index].qty))/shorts[index].lastPrice
            shorts[index].PnLPct = ((shorts[index].entryPrice - shorts[index].lastPrice)/shorts[index].lastPrice ) * 100
          } else {
            let price = get_price('BTC')
            let PnL = (((position.entry_price - price)/price ) * (position.size))/price
            let PnLPct = ((position.entry_price - price)/price) * 100
            shorts.push({
              symbol: 'XBTUSD', 
              entryPrice: +position.entry_price, 
              qty: -position.size, 
              lastPrice: price,
              PnL: PnL,
              PnLPct
            })
          }
        }else if (position.symbol.includes('USDT') == false){
          let index = shorts.findIndex(element => element.symbol === position.symbol && element.exchange === 'BYBIT')
          if (index > -1) {
            let qty = (Math.abs(shorts[index].qty)/shorts[index].entryPrice) + Math.abs(position.size)/position.entry_price
            shorts[index].entryPrice = (Math.abs(shorts[index].qty) + Math.abs(position.size)) / qty
            shorts[index].qty = (shorts[index].qty + -position.size)
            shorts[index].PnL = (((shorts[index].entryPrice - shorts[index].lastPrice)/shorts[index].lastPrice ) * (-shorts[index].qty))/shorts[index].lastPrice
            shorts[index].PnLPct = ((shorts[index].entryPrice - shorts[index].lastPrice)/shorts[index].lastPrice ) * 100
          } else {
            let price = get_price(position.symbol)
            let PnL = (((position.entry_price - price)/price ) * (position.size))/price
            let PnLPct = ((position.entry_price - price)/price) * 100
            shorts.push({
              symbol: position.symbol,
              exchange: "BYBIT",
              entryPrice: +position.entry_price, 
              qty: -position.size, 
              lastPrice: price,
              PnL: PnL,
              PnLPct
            })
          }
        } else {
          let index = shorts.findIndex(element => element.symbol === position.symbol && element.exchange === 'BYBIT')
          if (index > -1) {
            let entryPrice = ((shorts[index].qty * shorts[index].entryPrice) + (Math.abs(position.size) * position.entry_price))/(shorts[index].qty + Math.abs(position.size))
            let price = get_price(position.symbol)
            shorts[index].entryPrice = entryPrice
            shorts[index].qty = (shorts[index].qty + -position.size)
            shorts[index].PnL = (((shorts[index].entryPrice - price)/price ) * (-shorts[index].qty))
            shorts[index].PnLPct = ((shorts[index].entryPrice - price)/price ) * 100
          } else {
            let price = get_price(position.symbol)
            let PnL = (((position.entry_price - price)/price ) * (position.size))
            let PnLPct = ((position.entry_price - price)/price) * 100
            shorts.push({
              symbol: position.symbol,
              exchange: "BYBIT",
              entryPrice: +position.entry_price, 
              qty: -position.size, 
              lastPrice: price,
              PnL: PnL,
              PnLPct
            })
          }
        }
      } 

      })
      //console.log(longs)
      //console.log(shorts)
      let summary = longs.concat(shorts).sort((a, b) => Math.abs(b.qty) - Math.abs(a.qty))
      //console.log(summary)
      setPositionSummary(f => summary)
    }
    useEffect(() => {
        updateSummary()
    }, [bitmexPositions, bybitPositions, tickers])
    useEffect(() => {
      let w = {wallet_balance: 0, margin_balance: 0, realised_pnl: 0, unrealised_pnl: 0, wallet_balanceUSD: 0, margin_balanceUSD: 0, realised_pnlUSD: 0, unrealised_pnlUSD: 0}
      portfolio.forEach(p => {
        w.wallet_balanceUSD += p.wallet_balance * get_price(p.currency)
        w.margin_balanceUSD += p.margin_balance * get_price(p.currency)
        w.realised_pnlUSD += p.realised_pnl * get_price(p.currency)
        w.unrealised_pnlUSD += p.unrealised_pnl * get_price(p.currency)
      })
      w.wallet_balance = (+w.wallet_balanceUSD/ get_price('BTC')).toFixed(4)
      w.margin_balance = (+w.margin_balanceUSD/ get_price('BTC')).toFixed(4)
      w.realised_pnl = (+w.realised_pnlUSD/ get_price('BTC')).toFixed(4)
      w.unrealised_pnl = (+w.unrealised_pnlUSD/ get_price('BTC')).toFixed(4)
      //console.log(w)
      
      setWalletValue(f => w)

    }, [portfolio, tickers])
 

    const summaryRows = positionSummary.map((position) => (<tr>
        <td class="tx-medium tx-color-03"><h6 class="tx-rubik mg-b-1">{position.symbol === 'XBTUSD' ? 'BTCUSD' : position.symbol}</h6></td>
        <td className={position.qty > 0 ? "text-center tx-success" : "text-center tx-danger"}>{position.qty}</td>
        <td class="text-center tx-teal">{position.entryPrice.toFixed(2)}</td>

        <td class="text-center"><h6 class="tx-rubik mg-b-1">{position.lastPrice}</h6></td>
        <td class="tx-medium text-center"><span class={position.PnL > 0? 'tx-success' : 'tx-danger'}>{position.PnL.toFixed(4)}</span> {position.exchange === 'BITMEX'? 'BTC': position.symbol.split('USD')[0] } <span class={position.PnL > 0? 'mg-l-5 tx-10 tx-normal tx-success' : ' mg-l-5 tx-10 tx-normal tx-danger'}><i class={position.PnL > 0 ? "icon ion-md-arrow-up" : "icon ion-md-arrow-down"}></i> {position.PnLPct.toFixed(2)} %</span><p class="tx-10 tx-color-03 mg-b-0">${(position.PnL*get_price(position.symbol)).toFixed(2)} USD</p></td>
        </tr>
      ))
    const WalletRows = portfolio.filter(wallet => wallet.wallet_balance * get_price(wallet.currency) > 20).map((wallet) => (<tr>
        <td class="tx-medium tx-color-02"><h6 class="tx-rubik mg-b-1">{wallet.currency}</h6></td>
        <td class="text-center tx-color-02"><h6 class="tx-rubik mg-b-1">{wallet.wallet_balance.toFixed(4)}</h6> <p class="tx-10 tx-color-03 mg-b-0">${((wallet.wallet_balance)* get_price(wallet.currency)).toFixed(2)} USD</p></td>
        <td class="text-center tx-teal">{wallet.margin_balance.toFixed(4)}<p class="tx-10 tx-color-03 mg-b-0">${((wallet.margin_balance)* get_price(wallet.currency)).toFixed(2)} USD</p></td>
        <td className={wallet.realised_pnl > 0 ? "text-center tx-success" : "text-center tx-danger"}>{wallet.realised_pnl.toFixed(4)} <p class="tx-10 tx-color-03 mg-b-0">${((wallet.realised_pnl)* get_price(wallet.currency)).toFixed(2)} USD</p></td>
        <td class={wallet.unrealised_pnl > 0 ? "text-center tx-success" : "text-center tx-danger"}>{wallet.unrealised_pnl.toFixed(4)} <p class="tx-10 tx-color-03 mg-b-0">${((wallet.unrealised_pnl)* get_price(wallet.currency)).toFixed(2)} USD</p></td>
        <td class="tx-medium text-center tx-color-02" width="10%">{get_price(`${wallet.currency.toUpperCase()}`) == 1? "": get_price(`${wallet.currency.toUpperCase()}`)}</td>
        </tr>
      ))
    
      return (<div class="row">
      <div class="col-lg-12 mg-t-10">
      <div class="card mg-b-10">
        <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
          <div>
            <h3 class="mg-b-5">Portfolio</h3>
          </div>
        </div>
        <div class="card-body pd-b-10 pd-t-30">
                <div class="d-sm-flex">
                  
                  <div class="media">
                    <div class="wd-40 wd-md-50 ht-40 ht-md-50 bg-primary tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                    <BarChart2 />
                    </div>
                    <div class="media-body">
                      <h6 class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">Wallet Balance</h6>
                        <h4 class="tx-12 tx-sm-10 tx-md-14 tx-bold tx-rubik mg-b-0">{walletValue.wallet_balance} BTC</h4>
                        <h4 class="tx-10 tx-sm-8 tx-md-10 tx-bold tx-rubik mg-b-0 tx-color-03">{walletValue.wallet_balanceUSD? walletValue.wallet_balanceUSD.toFixed(0): ""} USD</h4>
                    </div>
                  </div>
                  <div class="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                    <div class="wd-40 wd-md-50 ht-40 ht-md-50 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-5">
                      <BarChart2 />
                    </div>
                    <div class="media-body">
                      <h6 class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">Margin Balance</h6>
                      <h4 class="tx-12 tx-sm-10 tx-md-14 tx-bold tx-rubik mg-b-0">{walletValue.margin_balance} BTC</h4>
                      <h4 class="tx-10 tx-sm-8 tx-md-10 tx-bold tx-rubik mg-b-0 tx-color-03">{walletValue.margin_balanceUSD? walletValue.margin_balanceUSD.toFixed(0): ""} USD</h4>
                    </div>
                  </div>
                  <div class="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                    <div class={walletValue.realised_pnl > 0 ? "wd-40 wd-md-50 ht-40 ht-md-50 bg-success tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4": "wd-40 wd-md-50 ht-40 ht-md-50 bg-danger tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4"}>
                    <BarChart2 />
                    </div>
                    <div class="media-body">
                      <h6 class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">Realised PNL</h6>
                      <h4 class="tx-12 tx-sm-10 tx-md-14 tx-bold tx-rubik mg-b-0">{walletValue.realised_pnl} BTC</h4>
                      <h4 class="tx-10 tx-sm-8 tx-md-10 tx-bold tx-rubik mg-b-0 tx-color-03">{walletValue.realised_pnlUSD? walletValue.realised_pnlUSD.toFixed(0): ""} USD</h4>
                    </div>
                  </div>
                  <div class="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                    <div class={walletValue.unrealised_pnl > 0 ? "wd-40 wd-md-50 ht-40 ht-md-50 bg-success tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4": "wd-40 wd-md-50 ht-40 ht-md-50 bg-danger tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4"}>
                    <BarChart2 />
                    </div>
                    <div class="media-body">
                      <h6 class="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">UnRealised PNL</h6>
                      <h4 class="tx-12 tx-sm-10 tx-md-14 tx-bold tx-rubik mg-b-0">{walletValue.unrealised_pnl} BTC</h4>
                      <h4 class="tx-10 tx-sm-8 tx-md-10 tx-bold tx-rubik mg-b-0 tx-color-03">{walletValue.unrealised_pnlUSD? walletValue.unrealised_pnlUSD.toFixed(0): ""} USD</h4>
                    </div>
                  </div>
                  
                </div>
              </div>
          <div class="card-body pd-b-10 pd-t-30">
            <h5 class="mg-b-0">Wallets</h5>
          </div>
          <div class="card-body">
          <div class="table-responsive">
          <table class="table table-dashboard mg-b-0">
            <thead>
              <tr>
                <th class="">Currency</th>
                <th class="text-center">Wallet Balance</th>
                <th class="text-center">Margin Balance</th>
                <th class="text-center">Realised PNL</th>
                <th class="text-center">UnRealised PNL</th>
                <th class="text-center">Ticker</th>
              </tr>
            </thead>
            <tbody>
              {WalletRows}
            </tbody>
          </table>
        </div>
        </div>
        <div class="card-body pd-b-10 pd-t-20">
            <h5 class="mg-b-0">Positions</h5>
        </div>
        <div class="card-body">
        <div class="table-responsive">
          <table class="table table-dashboard mg-b-0">
            <thead>
              <tr>
                <th class="">Symbol</th>
                <th class="text-center">size</th>
                <th class="text-center">entry</th>
                <th class="text-center" width="10%">Price</th>
                <th class="text-center">UnRealised PNL</th>
              </tr>
            </thead>
            <tbody>
              {summaryRows}
            </tbody>
          </table>
        </div>
        </div>
      </div>
    </div>
    </div>)
}