import React, {useEffect}from 'react';
import 'cryptofont/css/cryptofont.min.css'
import { useCookies } from 'react-cookie';
import {useGlobalState} from './store'
import Login from './Login'
import Loader from './Loader'
import App from './App'
require('bootstrap');

function Main() {
    const [loading, setloadding] = useGlobalState('loading')
    const [logged_in, setlogged_in] = useGlobalState('logged_in')
    useEffect(() => {
    }, [loading, logged_in])


    if (loading == true) {
        return <Loader/>
    }
    if (logged_in == false) {
        return <Login/>
    }
    return <App/>
}
export default Main;