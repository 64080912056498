import React, {useState} from 'react';
import {setGlobalState, useGlobalState, fetchAll} from './store'
import {Helmet} from "react-helmet";
import { useCookies } from 'react-cookie';

function Login() {
    const [cookies, setCookie] = useCookies(['theme']);
    const [logged_in, setlogged_in] = useGlobalState('logged_in')
    const [user, setlUser] = useGlobalState('user')
    const [client, setlClient] = useGlobalState('client')
    const [username, setusername] = useState('')
    const [password, setpassword] = useState('')
    const [disabled, setDisabled] = useState(false)
    const [error, seterror] = useState(null)
    const onSubmit = async (e) => {
        setDisabled(true)
        e.preventDefault()
        const fetchResult = await fetch('api/login', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({"username": username, 'password': password }) // body data type must match "Content-Type" header
          })
        if (fetchResult.ok) {
            const result = await fetchResult.json()
            if (result.success == true) {
                fetchAll()
                setlUser(result.user)
                setlClient(result.client)
                setlogged_in(true)
            } else {

                seterror('Invalid username or password')
                setDisabled(false)
            }
            //setGlobalState('logged_in', true)
        } else {
            setDisabled(false)
            seterror('Invalid username or password')
        }
    }
    return (<><Helmet>
        {cookies.theme == 'dark' &&
            (<link rel="stylesheet" href='/static/css/skin.dark.css'/>) }
        </Helmet><div class="content content-fixed content-auth">
      <div class="container">
        <div class="media align-items-stretch justify-content-center ht-100p pos-relative">
          
          <div class="sign-wrapper mg-lg-l-50 mg-xl-l-60">
            <div class="wd-100p">
              <h3 class="tx-color-01 mg-b-5">Sign In</h3>
              <p class="tx-color-03 tx-16 mg-b-40">Welcome back! Please signin to continue.</p>
              {error !== null && <div class="alert alert-danger" role="alert">{error}</div>}
            <form onSubmit={onSubmit}>
              <div class="form-group">
                <label>Username</label>
                <input type="text" autocomplete="off" name='username' class="form-control" placeholder="Enter your Username" value={username} onChange={e => setusername(e.target.value)}/>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mg-b-5">
                  <label class="mg-b-0-f">Password</label>
                </div>
                <input type="password" name="password" class="form-control" placeholder="Enter your password" value={password} onChange={e => setpassword(e.target.value)}/>
              </div>
              <button disabled={disabled} type='submit' class="btn btn-brand-02 btn-block">{disabled ? "Authenticating..." : "Sign In"}</button>
            </form>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    </>)
}
export default Login