import React, {useEffect, useState} from 'react';
import {socket} from '../../config'
import {useGlobalState, get_price} from '../../store'
import {Link} from 'react-router-dom'
import moment from 'moment'

import { AreaChart, XAxis,Tooltip, Area, ResponsiveContainer } from 'recharts'

export default function Summary (props) {
  const [wallet, setWallet] = useState(null)
  const [BTC, setBTC] = useGlobalState('BTC')
  const [transactions, setTransactions] = useState([])

  const getAccountDetail = () => {
    fetch(`/api/account/${props.account.id}`)
    .then(res => res.json())
    .then(data => {
        //console.log(data)
      if (data.wallet != null) {
        setWallet(f => data.wallet)
      }
      if (data.transactions != null) {
        data.transactions.reverse()
        setTransactions(f => data.transactions)
      }
      if (data.BTC) {
        setBTC(f => data.BTC)
      }

    })
  }

  useEffect(() => {
    getAccountDetail()
    socket.on(`bybit_${props.account.id}`, data => {
      //console.log(data)
      if (data.table === 'wallet') {
        setWallet(f => data.data)
      }
      if (data.table === 'transactions') {
        data.data.reverse()
        setTransactions(f => data.data)
    }
    })
    return () => {
      socket.off(`bybit_${props.account.id}`)
  }
    
  }, [])
    if (wallet == null) {
        return <div>Loading...</div>
    }
    return (
        <div class="col-sm-6 col-lg-6 col-xl-4 mg-t-10">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <Link to={`/bybit/${props.account.id}`}><h6 class="lh-5 mg-b-0">{props.account.title}</h6></Link>
                <p class="tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0">ByBit</p>
              </div>
              <div class="card-body pd-0 pos-relative">
                <div class="pos-absolute t-20 l-20">
                  <p class="tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0">Wallet Balance</p>
                  <div class=" align-items-baseline">
                    <h5 class="tx-normal tx-rubik tx-spacing--2 mg-b-0">{+wallet.wallet_balance.toFixed(4)} BTC</h5>
                      <span class="tx-rubik tx-color-03">${(+wallet.wallet_balance * get_price('BTC')).toFixed(2)} USD</span>
                  </div>
                </div>
                <div class="pos-absolute t-20 r-20">
                  <p class="tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0">Margin Balance</p>
                  <div class=" align-items-baseline">
                    <h5 class="tx-normal tx-rubik tx-spacing--2 mg-b-0">{wallet.equity.toFixed(4)} BTC</h5>
                      <span class="tx-rubik tx-color-03">${(+wallet.equity * get_price('BTC')).toFixed(2)} USD</span>
                  </div>
                </div>
                <div class="chart-fourteen">
                  <ResponsiveContainer width="107%" height={300}>
                <AreaChart  data={transactions}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#0168fa" stopOpacity={0.2}/>
                      <stop offset="95%" stopColor="#0168fa" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <XAxis tick={{stroke: '#8392a5', strokeWidth: 0.01}} interval={'preserveStart'} minTickGap={20} axisLine={false} tickLine={false} dataKey="exec_time" tickFormatter={xAxsisFormat} />
                  
                  <Tooltip content={<CustomTooltip />}/>
                  
                  <Area type="monotone" dataKey="wallet_balance" stroke="#0168fa" fillOpacity={1} fill="url(#colorPv)" />
                </AreaChart>
                </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
    )
}

const xAxsisFormat = (ts) => {
  let dt = new Date(ts)
  return moment(dt).format('DD MMM')
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
      if (payload == null) {
          return null
      }
    let dt = new Date(label)

    return (
      <div className="custom-tooltip">
        <h5 class="tx-normal tx-rubik tx-spacing--2 mg-b-0"><i class="cf cf-btc"></i>{(+payload[0].value).toFixed(4)}</h5>
        <p className="tx-color-03">{moment(dt).format('DD MMM, YY')}</p>
      </div>
    );
  }

  return null;
};