import React, {useEffect, useState} from 'react';
import {socket} from '../../config'
import {useGlobalState} from '../../store'
import {Link} from 'react-router-dom'
import moment from 'moment'

import { AreaChart, XAxis,Tooltip, Area, ResponsiveContainer } from 'recharts'

export default function Summary (props) {
  const [margin, setMargin] = useState(null)
  const [XBT, setXBT] = useGlobalState('XBT')
  const [account, setAccount] = useState(null)
  const [withdrawals, setWithdrawals] = useState(0)
  const [transactions, setTransactions] = useState([])

  const getAccountDetail = () => {
    fetch(`/api/account/${props.account.id}`)
    .then(res => res.json())
    .then(data => {
      if (data.margin != null) {
        setMargin(f => data.margin[0])
      }
      if (data.account != null) {
        setAccount(f => data.account)
      }
      if (data.wallet_transactions != null) {
        data.wallet_transactions.reverse()
        if (data.account && data.account.remove_withdrawal) {
          let totalWithdraw = 0;
          let trx = data.wallet_transactions.map(tx => {
            if (tx.transactType === "Withdrawal") {
              totalWithdraw += -tx.amount
            }
            tx.walletBalance += totalWithdraw
            return tx
          }).filter(tx => tx.transactType !== "Withdrawal")
          
          if (data.account && data.account.strategy_start_date) {
            let trxf = trx.filter(tx => new Date(tx.timestamp) > new Date(data.account.strategy_start_date))
            setTransactions(f => trxf)
          } else {
            setTransactions(f => trx)
          }
          setWithdrawals(f => totalWithdraw)
        } else {
          if (data.account && data.account.strategy_start_date) {
            let trx = data.wallet_transactions.filter(tx => new Date(tx.timestamp) > new Date(data.account.strategy_start_date))
            setTransactions(f => trx)
          } else {
            setTransactions(f => data.wallet_transactions)
          }
        }
        
      }
      if (data.XBT) {
        setXBT(f => data.XBT)
      }

    })
  }

  useEffect(() => {
    getAccountDetail()
    socket.on(`BITMEX_${props.account.id}`, data => {
      //console.log(data)
      if (data.table === 'margin') {
        setMargin(f => data.data[0])
      }
      if (data.table === 'wallet_transactions') {
        data.data.reverse()
        if (account && account.remove_withdrawal) {
          let totalWithdraw = 0;
          let trx = data.data.map(tx => {
            if (tx.transactType === "Withdrawal") {
              totalWithdraw += -tx.amount
            }
            tx.walletBalance += totalWithdraw
            return tx
          }).filter(tx => tx.transactType !== "Withdrawal")
          
          if (account && account.strategy_start_date) {
            let trxf = trx.filter(tx => new Date(tx.timestamp) > new Date(data.account.strategy_start_date))
            setTransactions(f => trxf)
          } else {
            setTransactions(f => trx)
          }
          setWithdrawals(f => totalWithdraw)
        } else {
          if (account && account.strategy_start_date) {
            let trx = data.data.filter(tx => new Date(tx.timestamp) > new Date(data.account.strategy_start_date))
            setTransactions(f => trx)
          } else {
            setTransactions(f => data.wallet_transactions)
          }
        }
    }
    })
    return () => {
      socket.off(`BITMEX_${props.account.id}`)
  }
    
  }, [])
  const AMOUNT = margin !== null ? (margin.walletBalance /100000000).toFixed(4) : 0
  const AMOUNTUSD = margin !== null && XBT !== null ? ((margin.walletBalance /100000000) * XBT).toFixed(2) : 0

  const MARGIN = margin !== null ? (margin.marginBalance /100000000).toFixed(4) : 0
  const MARGINUSD = margin !== null && XBT !== null ? ((margin.marginBalance /100000000) * XBT).toFixed(2) : 0
  const URPNL = margin !== null ? margin.unrealisedPnl /100000000 : 0
  const URPNLUSD = margin !== null && XBT !== null ? ((margin.unrealisedPnl /100000000) * XBT).toFixed(2) : 0
  const RPNL = margin !== null ? margin.realisedPnl /100000000 : 0
  const RPNLUSD = margin !== null && XBT !== null ? ((margin.realisedPnl /100000000) * XBT).toFixed(2) : 0
    return (
        <div class="col-sm-6 col-lg-6 col-xl-4 mg-t-10">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <Link to={`/bitmex/${props.account.id}`}><h6 class="lh-5 mg-b-0">{props.account.title}</h6></Link>
                <p class="tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0">BitMEX</p>
              </div>
              <div class="card-body pd-0 pos-relative">
                <div class="pos-absolute t-20 l-20">
                  <p class="tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0">Wallet Balance</p>
                  <div class=" align-items-baseline">
                    <h5 class="tx-normal tx-rubik tx-spacing--2 mg-b-0">{AMOUNT} XBT</h5>
                      <span class="tx-rubik tx-color-03">${AMOUNTUSD} USD</span>
                  </div>
                </div>
                <div class="pos-absolute t-20 r-20">
                  <p class="tx-uppercase tx-11 tx-spacing-1 tx-color-03 tx-medium mg-b-0">Margin Balance</p>
                  <div class=" align-items-baseline">
                    <h5 class="tx-normal tx-rubik tx-spacing--2 mg-b-0">{MARGIN} XBT</h5>
                      <span class="tx-rubik tx-color-03">${MARGINUSD} USD</span>
                  </div>
                </div>
                <div class="chart-fourteen">
                  <ResponsiveContainer width="107%" height={300}>
                <AreaChart  data={transactions}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#0168fa" stopOpacity={0.2}/>
                      <stop offset="95%" stopColor="#0168fa" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <XAxis tick={{stroke: '#8392a5', strokeWidth: 0.01}} interval={'preserveStart'} minTickGap={20} axisLine={false} tickLine={false} dataKey="timestamp" tickFormatter={xAxsisFormat} />
                  
                  <Tooltip content={<CustomTooltip />}/>
                  
                  <Area type="monotone" dataKey="walletBalance" stroke="#0168fa" fillOpacity={1} fill="url(#colorPv)" />
                </AreaChart>
                </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
    )
}

const xAxsisFormat = (ts) => {
  let dt = new Date(ts)
  return moment(dt).format('DD MMM')
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    if (payload == null) {
      return null
  }
    let dt = new Date(label)

    return (
      <div className="custom-tooltip">
        <h5 class="tx-normal tx-rubik tx-spacing--2 mg-b-0"><i class="cf cf-btc"></i>{(payload[0].value/100000000).toFixed(4)}</h5>
        <p className="tx-color-03">{moment(dt).format('DD MMM, YY')}</p>
      </div>
    );
  }

  return null;
};