import React from 'react';
import {Route, Routes } from 'react-router-dom'
import {Helmet} from "react-helmet";
import Dashboard from './Components/dashboard'
import Performance from './Components/performance'
import Bitmex from './Components/bitmex'
import ByBit from './Components/bybit'
import Ftxus from './Components/ftxus'
import Settings from './Components/accounts'
import NewAccount from './Components/accounts/newAccount'
import Nav from './Components/nav/Nav'
import { useCookies } from 'react-cookie';
import {useGlobalState, get_price} from './store'
import MobilePrices from './Components/nav/MobilePrices';


function App() {
  const [cookies, setCookie] = useCookies(['theme']);
  return (
    <>
    <Helmet>
  {cookies.theme == 'dark' &&
      (<link rel="stylesheet" href='/static/css/skin.dark.css'/>) }
  </Helmet>
    <Nav />
    <MobilePrices/>
    <div className="content content-fixed">
    <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
    <Routes>
      <Route path="/" element={<Dashboard/>}/>
      <Route path="/performance" element={<Performance/>}/>
      <Route path="/bitmex/:account" element={<Bitmex/>}/>
      <Route path="/bybit/:account" element={<ByBit/>}/>
      <Route path="/ftxus/:account" element={<Ftxus/>}/>
      <Route path="/settings" element={<Settings/>}/>
      <Route path="/new-account" element={<NewAccount/>}/>
    </Routes>
    </div> </div>
    </>
  );
}

export default App;
