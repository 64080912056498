
import React, {useEffect} from 'react'
import {useGlobalState, get_price} from '../../store'

const MobilePrices = () => {
    const [tickers, setTickers] = useGlobalState('tickers')

    useEffect(() => {
        
    }, [tickers])

    
  return (
    <div class="card d-sm-block d-md-none tx-color-03 bd-t mg-b-5">
      <div class="d-flex justify-content-between ">
      <div class="tx-color-02 tx-rubik tx-20 mg-l-20"><i class="cf cf-btc tx-orange"> </i> {get_price('BTC').toFixed(1)}</div>
            
    <div class='mg-r-20 tx-color-02 tx-rubik tx-20'><i class="cf cf-eth"> </i>{get_price('ETH').toFixed(2)} </div>
    </div>
    </div>
  )
}

export default MobilePrices