import React from "react";
import {Helmet} from "react-helmet";
import { useCookies } from 'react-cookie';

function Loader() {
    const [cookies, setCookie] = useCookies(['theme']);
    return (<><Helmet>
        {cookies.theme == 'dark' &&
            (<link rel="stylesheet" href='/static/css/skin.dark.css'/>) }
        </Helmet><div class="spinner"><div class="spinner-grow spinner-grow text-info" role="status"></div></div></>)
}

export default Loader