import React, {useEffect, useState} from 'react';
import {useGlobalState} from '../../store'
import {socket} from '../../config'
import {useParams} from 'react-router-dom'
import {Link} from 'react-router-dom'
import { AreaChart, XAxis,Tooltip, Area, ResponsiveContainer, CartesianGrid, YAxis } from 'recharts'
import moment from 'moment'


export default function BitBit() {
    let params = useParams()
    const [wallet, setWallet] = useState({wallet_balance: 0, equity: 0, unrealised_pnl: 0, realised_pnl: 0, wallet_balanceUSD: 0, equityUSD: 0, unrealised_pnlUSD: 0, realised_pnlUSD: 0})
    const [position, setPosition] = useState([])
    const [account, setAccount] = useState(null)
    const [tickers] = useGlobalState('tickers')
    const [bybitWallets] = useGlobalState('bybitWallets')
    const [filter, setFilter] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [activeChart, setactiveChart] = useState('USDT')
    const [BTC, setBTC] = useGlobalState('BTC')
    const [transactions, setTransactions] = useState([])
    const [withdrawals, setWithdrawals] = useState(0)
    const [filteredTransactions, setFilteredTransactions] = useState([])
    const [allTransactions, setAllTransactions] = useState([])

    const get_price = (symbol) => {
      symbol = symbol.replace('XBT', 'BTC')
      if (symbol === 'USDT') {
          return 1
      }
      if (tickers[symbol]) {
          return tickers[symbol].price
      }
      if (tickers[`${symbol}USDT`]) {
       return tickers[`${symbol}USDT`].price
      } else {return 1}
  }
    
    const ft = (k, coin) => {
      let fts = transactions.filter(t => t.coin == coin).filter(transaction => {
        if (k === 'a') {
          return true
        }
        let dt = new Date(transaction.exec_time)
        let now = new Date()
        if (k === 'y') {
          now.setDate(now.getDate() - 365);
          return dt > now;
        }
        if (k === '6m') {
          now.setDate(now.getDate() - 182);
          return dt > now;
        }
        if (k === '3m') {
          now.setDate(now.getDate() - 90);
          return dt > now;
        }
        if (k === 'm') {
          now.setDate(now.getDate() - 30);
          return dt > now;
        }
        if (k == 'date') {
            return dt > new Date(startDate)
        }
  
      })
      setFilteredTransactions(f => fts)
      
    }
    function calculatePnlPcnt(position) {
      if (position.side === 'Buy') {
          let pnl = ((tickers[position.symbol].price - position.entry_price)/position.entry_price) * 100
          if (pnl > 0) {
              return pnl.toFixed(2)
          } else {
              return (-pnl).toFixed(2)
          }
      } else {
          let pnl = ((position.entry_price - tickers[position.symbol].price)/tickers[position.symbol].price ) * 100
          if (pnl > 0) {
              return pnl.toFixed(2)
          } else {
              return (-pnl).toFixed(2)
          }
      }
  }
  function calculatePnl(position) {
    if (position.symbol.includes('USDT')) {
      if (position.side === 'Buy') {
        let pnl = (((tickers[position.symbol].price - position.entry_price)/position.entry_price) * position.size)
        return pnl
    } else {
        let pnl = (((position.entry_price - tickers[position.symbol].price)/tickers[position.symbol].price ) * (position.size))
        return pnl
    }
    }else {
      if (position.side === 'Buy') {
          let pnl = (((tickers[position.symbol].price - position.entry_price)/position.entry_price) * position.size)/tickers[position.symbol].price
          return pnl
      } else {
          let pnl = (((position.entry_price - tickers[position.symbol].price)/tickers[position.symbol].price ) * (position.size))/tickers[position.symbol].price
          return pnl
      }
    }
  }
    const getAccountDetail = () => {
      fetch(`/api/account/${params.account}`)
      .then(res => res.json())
      .then(data => {
          //console.log(data)
        if (data.transactions != null) {
            data.transactions.reverse()
            setAllTransactions(f => data.transactions)
            if (data.account && data.account.remove_withdrawal) {
              let totalWithdraw = 0;
              let trx = data.transactions.map(tx => {
                if (tx.type === "Withdraw") {
                  totalWithdraw += -tx.amount
                }
                tx.wallet_balance = +tx.wallet_balance
                tx.wallet_balance += totalWithdraw
                return tx
              }).filter(tx => tx.type !== "Withdraw")
              setTransactions(f => trx)
              setWithdrawals(f => totalWithdraw)
            } else {
              setTransactions(f => data.transactions)
            }
            if (data.account != null && data.account.strategy_start_date != null) {
              setStartDate(f => data.account.strategy_start_date)
              setFilter(f =>'date')
            } else {
              setFilter(f => 'a')
            }
            
          }
        if (data.wallet != null) {
          let wallet = data.wallet.find(w => w.currency === 'BTC')
          //setWallet(f => wallet)
        }
        if (data.account != null) {
          setAccount(f => data.account)
        }
        
        if (data.position != null) {
            setPosition(f => data.position)
        }
        if (data.BTC) {
          setBTC(f => data.BTC)
        }
  
      })
    }

    const updateWallet = () => {
      //w.wallet_balance * get_price(w.currency) > 20 && 
      const wallets = bybitWallets.filter(w => w.user_id === parseInt(params.account))
      let wall = {wallet_balance: 0, equity: 0, unrealised_pnl: 0, realised_pnl: 0, wallet_balanceUSD: 0, equityUSD: 0, unrealised_pnlUSD: 0, realised_pnlUSD: 0}
      for (let w of wallets) {
        //wall.wallet_balance += w.wallet_balance * get_price(w.currency)
        //wall.equity += w.equity * get_price(w.currency)
        //wall.unrealised_pnl += w.unrealised_pnl * get_price(w.currency)
        //wall.realised_pnl += w.realised_pnl * get_price(w.currency)
        wall.wallet_balanceUSD += w.wallet_balance * get_price(w.currency)
        wall.equityUSD += w.equity * get_price(w.currency)
        wall.unrealised_pnlUSD += w.unrealised_pnl * get_price(w.currency)
        wall.realised_pnlUSD += w.realised_pnl * get_price(w.currency)
      }
      wall.wallet_balance = wall.wallet_balanceUSD / get_price('BTC')
      wall.equity = wall.equityUSD / get_price('BTC')
      wall.unrealised_pnl = wall.unrealised_pnlUSD / get_price('BTC')
      wall.realised_pnl = wall.realised_pnlUSD / get_price('BTC')
      setWallet(f => wall)
      //console.log(wall)
    }
    useEffect(() => {
      updateWallet()
    }, [tickers])
    useEffect(() => {
      ft(filter, activeChart)
    }, [filter, startDate, activeChart])
  
    useEffect(() => {
      getAccountDetail()
      socket.on(`bybit_${params.account}`, data => {
        //console.log(data)
        if (data.table === 'transactions') {
          data.data.reverse()
          if (account && account.remove_withdrawal) {
            let totalWithdraw = 0;
            let trx = data.data.map(tx => {
              if (tx.type === "Withdraw") {
                totalWithdraw += -tx.amount
              }
              tx.wallet_balance = +tx.wallet_balance
              tx.wallet_balance += totalWithdraw
              return tx
            }).filter(tx => tx.type !== "Withdraw")
            setTransactions(f => trx)
            setWithdrawals(f => totalWithdraw)
          } else {
            setTransactions(f => data.transactions)
          }
          setTransactions(f => data.data)
          setFilter(f => f)
          
      }
        if (data.table === 'wallet') {
          //setWallet(f => data.data)
        }
        if (data.table === 'position') {
          let ops = position.length
            setPosition(f => data.data)
          if (ops < position.length) {
            socket.send('sync-account', params.account)
          }
          
          }
        
      })
      return () => {
          socket.off(`bybit_${params.account}`)
      }
      
    }, [params.account])
    if (wallet == null) {
      return <div>loading...</div>
    }
    
    const bybitRows = bybitWallets.filter(w => w.wallet_balance * get_price(w.currency) > 20 && w.user_id === parseInt(params.account)).map(row => [<tr>
      <td class="tx-medium text-center"><a data-toggle="collapse" href={`#collapse${row.currency}`} role="button" aria-expanded="false" aria-controls={`collapse${row.currency}`}>{row.currency}</a></td>
      <td class="tx-medium text-center">{(row.wallet_balance).toFixed(4)} {row.currency}<p class="tx-10 tx-color-03 mg-b-0">${((row.wallet_balance)* get_price(row.currency)).toFixed(2)} USD</p></td>
      <td class="tx-medium text-center"><span class='tx-teal'>{(row.equity).toFixed(4)}</span> {row.currency}<p class="tx-10 tx-color-03 mg-b-0">${((+row.equity)* get_price(row.currency)).toFixed(2)} USD</p></td>
      <td class="tx-medium text-center"><span class={row.realised_pnl > 0? 'tx-success' : 'tx-danger'}>{(row.realised_pnl).toFixed(4)}</span> {row.currency} <p class="tx-10 tx-color-03 mg-b-0">${((row.realised_pnl)* get_price(row.currency)).toFixed(2)} USD</p></td>
      <td class="tx-medium text-center"><span class={row.unrealised_pnl > 0? 'tx-success' : 'tx-danger'}>{(row.unrealised_pnl).toFixed(4)}</span> {row.currency} <p class="tx-10 tx-color-03 mg-b-0">${((row.unrealised_pnl)* get_price(row.currency)).toFixed(2)} USD</p></td>
      
      </tr>,
      <tr class="collapse mg-t-5"  id={`collapse${row.currency}`}>
      <td class="col-12 mg-t-10" colspan="5">
              <div class="d-flex align-items-center mg-t-30 justify-content-between">
              <h6 class="tx-12 tx-semibold tx-uppercase tx-spacing-1  mg-b-5">Position Margin</h6>
              <h6 class="tx-12 tx-semibold tx-uppercase tx-spacing-1  mg-b-5">{(row.position_margin).toFixed(4)} {row.currency}</h6>
              </div>
              <div class="progress ht-2 mg-b-10 justify-content-end">
              <div class="progress-bar bg-danger" style={{'width': (100 -(((row.position_margin - row.equity) / wallet.equity) * 100 * -1)).toString() +'%'}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div class="d-flex align-items-center mg-t-20 justify-content-between">
              <h6 class="tx-12 tx-semibold tx-uppercase tx-spacing-1 mg-b-5">Order Margin</h6>
              <h6 class="tx-12 tx-semibold tx-uppercase tx-spacing-1 mg-b-5">{(row.order_margin).toFixed(4)} {row.currency}</h6>
              </div>
              <div class="progress ht-2 mg-b-10 justify-content-end">
              <div class="progress-bar bg-warning" style={{'width': (100 -(((row.order_margin - row.equity) / row.equity) * 100 * -1)).toString() +'%'}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              <div class="progress-bar progress" style={{'width': (100 -(((row.position_margin - row.equity) / row.equity) * 100 * -1)).toString() +'%'}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div class="d-flex align-items-center mg-t-20 justify-content-between">
              <h6 class="tx-12 tx-semibold tx-uppercase tx-spacing-1 mg-b-5">Available Balance</h6>
              <h6 class="tx-12 tx-semibold tx-uppercase tx-spacing-1 mg-b-5">{ (100 - (row.available_balance/row.equity) *100).toFixed(2) }% Margin Used</h6>
              <h6 class="tx-12 tx-semibold tx-uppercase tx-spacing-1 mg-b-5">{(row.available_balance).toFixed(4) } {row.currency}</h6>
              </div>
              <div class="progress ht-2 mg-b-10">
              <div class="progress-bar bg-success" style={{'width': ( (row.available_balance/row.equity) *100).toString() +'%'}} role="progressbar" aria-valuenow={( (row.equity/row.available_balance) *100)} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </td>
          
    </tr>])
    const equityChartTabs = bybitWallets.filter(w => w.wallet_balance * get_price(w.currency) > 20 && w.user_id === parseInt(params.account)).map(row => [
    <li class="nav-item">
      <a onClick={() => setactiveChart(row['currency'])}  class={row['currency'] == activeChart? "nav-link active" : "nav-link"} id={`${row['currency']}-tab`} data-toggle="tab" href={`#${row['currency']}-detail`} role="tab" aria-controls={`${row['currency']}-tab`} aria-selected="true"><i class={`cf cf-${row['currency'].toLowerCase()}`}></i>   {row['currency']}</a>
    </li>
  ])
    const equityCharts = bybitWallets.filter(w => w.wallet_balance * get_price(w.currency) > 20 && w.user_id === parseInt(params.account)).map(row => [<div class={row['currency'] == activeChart? "tab-pane fade show active" : "tab-pane fade"} id={`${row['currency']}-detail`} role="tabpanel" aria-labelledby={`${row['currency']}-tab`}>
    
              <div class="card-header pd-y-8 d-sm-flex align-items-center justify-content-between">
                <nav class="nav nav-line">
                {account != null && account.strategy_start_date &&
                  <span onClick={() => setFilter('date')} style={{"cursor": "pointer"}} className={filter === 'date' ? "nav-link active" : 'nav-link'}>{`${new Date(account.strategy_start_date).getMonth()+1}/${new Date(account.strategy_start_date).getDate()}/${new Date(account.strategy_start_date).getFullYear()}`}</span>
                  }
                  <span onClick={() => setFilter('m')} style={{"cursor": "pointer"}} className={filter === 'm' ? "nav-link active" : 'nav-link'}>Month</span>
                  <span onClick={() => setFilter('3m')} style={{"cursor": "pointer"}} className={filter === '3m' ? "nav-link active" : 'nav-link'}>3 Months</span>
                  <span onClick={() => setFilter('6m')} style={{"cursor": "pointer"}} className={filter === '6m' ? "nav-link active" : 'nav-link'}>6 Months</span>
                  <span onClick={() => setFilter('y')} style={{"cursor": "pointer"}}  className={filter === 'y' ? "nav-link active" : 'nav-link'}>Year</span>
                  <span onClick={() => setFilter('a')} style={{"cursor": "pointer"}} className={filter === 'a' ? "nav-link active" : 'nav-link'}>All</span>
                </nav>
                <div>
                  {account !== null && account.remove_withdrawal === true && (<i class="icon ion-md-alert" style={{"cursor": "pointer"}} data-toggle="tooltip" data-placement="top" title={`Total Withdrawals: ${withdrawals.toFixed(4)} BTC`}></i>)}
                  <i class="icon ion-md-refresh mg-l-10" style={{"cursor": "pointer"}} data-toggle="tooltip" data-placement="top" title="Sync Account" onClick={e => {socket.emit('sync-account', account.id)}}></i>
                </div>
              </div>
              <div class="card-body pd-10 pd-sm-20">
                <div class="chart-eleven">
                <ResponsiveContainer width="100%" height={450}>
                <AreaChart  data={filteredTransactions}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#0168fa" stopOpacity={0.2}/>
                      <stop offset="95%" stopColor="#0168fa" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <XAxis tick={{stroke: '#8392a5', strokeWidth: 0.01}} interval={'preserveStart'} minTickGap={50} axisLine={false} tickLine={false} dataKey="exec_time" tickFormatter={xAxsisFormat} />
                  <YAxis tick={{stroke: '#8392a5', strokeWidth: 0.01}} tickLine={false} axisLine={false} tickFormatter={yAxsisFormat}/>
                  
                  <Tooltip content={<CustomTooltip />}/>
                  <Area type="monotone" dataKey="wallet_balance" stroke="#0168fa" fillOpacity={1} fill="url(#colorPv)" />
                </AreaChart>
                </ResponsiveContainer>
                </div>
              </div>
              
          
  </div>])
    const positionRows = position.filter(p => p.size != 0).map(position => (<tr>
      <td class="tx-color-03 tx-normal">{position.symbol}</td>
      <td className={position.side == 'Buy' ? "text-center tx-success" : "text-center tx-danger"}>{position.side == 'Buy' ? position.size : -position.size}</td>
    <td class="text-center tx-teal">{(+position.entry_price).toFixed(2)}</td>
      <td class="text-center tx-pink">{position.liq_price}</td>
      
      <td class="tx-medium text-center" width="10%">{tickers[`${position.symbol}`].price} </td>
    <td class="tx-medium text-center">{(calculatePnl(position)).toFixed(4)} {position.symbol.split('USD')[0]} <span className={(position.side == 'Buy' && position.entry_price < tickers[`${position.symbol}`].price) || (position.side == 'Sell' && position.entry_price > tickers[`${position.symbol}`].price) ? 'mg-l-5 tx-10 tx-normal tx-success': 'mg-l-5 tx-10 tx-normal tx-danger'}><i className={(position.side == 'Buy' && position.entry_price < tickers[`${position.symbol}`].price) || (position.side == 'Sell' && position.entry_price > tickers[`${position.symbol}`].price) ? "icon ion-md-arrow-up" : "icon ion-md-arrow-down"}></i> {calculatePnlPcnt(position)}%</span><p class="tx-10 tx-color-03 mg-b-0">${(calculatePnl(position) * tickers[`${position.symbol}`].price).toFixed(2) } USD</p></td>
    </tr>))
    return (
        <>
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                <li class="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
                <li class="breadcrumb-item" aria-current="page">ByBit</li>
                <li class="breadcrumb-item active" aria-current="page">{params.account}</li>
              </ol>
            </nav>
            {account !== null &&
              <h2 class="mg-b-0 tx-spacing--1">{account.title}</h2>
            }
            
          </div>
        </div>

        <div class="row row-xs">
        <div class="col-sm-6 col-lg-3">
            <div class="card card-body">
              <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Wallet Balance</h6>
              <div class="d-flex d-lg-block d-xl-flex align-items-end">
          <h4 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{+wallet.wallet_balance.toFixed(4)} BTC</h4>
                <p class="tx-11 tx-color-03 mg-b-0"> ${(+wallet.wallet_balanceUSD).toFixed(2)} USD</p>
              </div>
              <div class="chart-three">
                  <div id="flotChart3" class="flot-chart ht-30"></div>
                </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 mg-t-10 mg-sm-t-0">
            <div class="card card-body">
              <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Margin Balance</h6>
              <div class="d-flex d-lg-block d-xl-flex align-items-end">
                <h4 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{+wallet.equity.toFixed(4)} BTC</h4>
                <p class="tx-11 tx-color-03 mg-b-0">${(+wallet.equityUSD).toFixed(2)} USD</p>
              </div>
              <div class="chart-three">
                  <div id="flotChart4" class="flot-chart ht-30"></div>
                </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
            <div class="card card-body">
              <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Realized PNL</h6>
              <div class="d-flex d-lg-block d-xl-flex align-items-end">
                <h4 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"><span class={wallet.realised_pnl > 0 ? "tx-success" : "tx-danger"}>{wallet.realised_pnl.toFixed(4)}</span> BTC</h4>
                <p class="tx-11 tx-color-03 mg-b-0">${(wallet.realised_pnlUSD).toFixed(2)} USD</p>
              </div>
              <div class="chart-three">
                  <div id="flotChart6" class="flot-chart ht-30"></div>
                </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-3 mg-t-10 mg-lg-t-0">
            <div class="card card-body">
              <h6 class="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">UnRealised PNL</h6>
              <div class="d-flex d-lg-block d-xl-flex align-items-end">
                <h4 class="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1"><span class={wallet.unrealised_pnl > 0 ? "tx-success" : "tx-danger"}>{wallet.unrealised_pnl.toFixed(4)}</span> BTC</h4>
                <p class="tx-11 tx-color-03 mg-b-0">${(wallet.unrealised_pnlUSD).toFixed(2)} USD</p>
              </div>
              <div class="chart-three">
                  <div id="flotChart5" class="flot-chart ht-30"></div>
                </div>
            </div>
          </div>
          
          
          <div class="col-lg-12 mg-t-10">
            <div class="card mg-b-10">
              <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                <div>
                  <h2 class="mg-b-5">Wallets</h2>
                </div>
              </div>
              <div class="card-body pd-y-30">
                
              <div class="table-responsive">
                <table class="table table-dashboard mg-b-0">
                  <thead>
                    <tr>
                    <th class="text-center">Currency</th>
                    <th class="text-center">Wallet Bal.</th>
                      <th class="text-center">Margin Bal.</th>
                      <th class="text-center">Realised PNL</th>
                      <th class="text-center">UnRealised PNL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bybitRows}
                  </tbody>
                </table>
              </div>
              </div>
            </div>
          </div>
        
          <div class="col-lg-12 mg-t-10">
            <div class="card mg-b-10">
              <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                <div>
                  <h2 class="mg-b-5">Positions</h2>
                </div>
              </div>
              <div class="card-body pd-y-30">
                
              <div class="table-responsive">
                <table class="table table-dashboard mg-b-0">
                  <thead>
                    <tr>
                      <th>Symbol</th>
                      <th class="text-center">Size</th>
                      <th class="text-center">Entry</th>
                      <th class="text-center">LIQ</th>
                      
                      <th class="text-center" width="10%">Price</th>
                      <th class="text-center">UnRealised PNL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {positionRows}
                  </tbody>
                </table>
              </div>
              </div>
            </div>
          </div>
          <div class='col-lg-12 mg-t-10'>
          <div class="card mg-b-10">
              <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                <div>
                  <h2 class="mg-b-5">Equity Charts</h2>
                </div>
              </div>
              <div class="card-body pd-y-30">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                      {equityChartTabs}
                    </ul>
                    <div class="tab-content bd bd-gray-300 bd-t-0 pd-20" id="myTabContent">
                      {equityCharts}
                    </div>
              </div>
          </div>
          </div>
          </div>
    </>
    )
}

const xAxsisFormat = (ts) => {
  let dt = new Date(ts)
  return moment(dt).format('DD MMM')
}

const yAxsisFormat = (xbt) => {
  return (xbt)
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    if (payload == null) {
      return null
    }
    let dt = new Date(label)
    return (
      <div className="custom-tooltip">
        <h5 class="tx-normal tx-rubik tx-spacing--2 mg-b-0"><i class={`cf cf-${payload[0].payload.coin.toLowerCase()}`}></i>{(+payload[0].value).toFixed(4)}</h5>
        <small className={payload[0].payload.amount > 0 ? 'tx-success' : 'tx-danger'}>{payload[0].payload.address || payload[0].payload.type} : <i class={`cf cf-${payload[0].payload.coin.toLowerCase()}`}></i> {(+payload[0].payload.amount).toFixed(4)} </small>
        <p className="tx-color-03">{moment(dt).format('DD MMM, YY')}</p>
      </div>
    );
  }

  return null;
};