import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {socket} from '../../config'
import {useGlobalState, get_price} from '../../store'
import bitmeximg from '../../images/bitmex.png'
import bybitimg from '../../images/bybit.png'
import { Octagon, AlertOctagon, XOctagon } from 'react-feather';



export default function Positions() {
    const [accounts, setAccounts] = useGlobalState('accounts')
    
    const [XBT, setXBT] = useGlobalState('XBT')
    const [BTC, setBTC] = useGlobalState('BTC')
    const [bitmexWallets, setBitmexWallets] = useGlobalState('bitmexWallets')
    const [bybitWallets, setBybitWallets] = useGlobalState('bybitWallets')
    const [positionSummary, setPositionSummary] = useGlobalState('positionSummary')
    const [bitmexPositions, setBitmexPositions] = useGlobalState('bitmexPositions')
    const [bybitPositions, setbybitPositions] = useGlobalState('bybitPositions')
    const [bitmexOrders, setBitmexOrders] = useState([])
    const [bybitOrders, setBybitOrders] = useState([])
    const [bitmexRisks, setBitmexRisks] = useState([])
    const [bybitRisks, setBybitRisks] = useState([])

    function calculatePnlPcnt(position) {
        if (position.side === 'Buy') {
            let pnl = ((get_price(position.symbol) - position.entry_price)/position.entry_price) * 100
            if (pnl > 0) {
                return pnl.toFixed(2)
            } else {
                return (-pnl).toFixed(2)
            }
        } else {
            let pnl = ((position.entry_price - get_price(position.symbol) )/get_price(position.symbol) ) * 100
            if (pnl > 0) {
                return pnl.toFixed(2)
            } else {
                return (-pnl).toFixed(2)
            }
        }
    }
    function calculatePnl(position) {
      if (position.symbol.includes('USDT')) {
        if (position.side === 'Buy') {
            let pnl = (((get_price(position.symbol)  - position.entry_price)/position.entry_price) * position.size)
            //console.log(pnl*tickers[position.symbol].price)
            return pnl
        } else {
            let pnl = (((position.entry_price - get_price(position.symbol) )/get_price(position.symbol)  ) * (position.size))
            //console.log(pnl*tickers[position.symbol].price)
            return pnl
        }
      } else {
        if (position.side === 'Buy') {
          let pnl = (((get_price(position.symbol)  - position.entry_price)/position.entry_price) * position.size) / get_price(position.symbol) 
          //console.log(pnl*tickers[position.symbol].price)
          return pnl
      } else {
          let pnl = (((position.entry_price - get_price(position.symbol) )/get_price(position.symbol)  ) * (position.size)) / get_price(position.symbol) 
          //console.log(pnl*tickers[position.symbol].price)
          return pnl
      }
      }
    }
    const getPositions = () => {
        fetch('/api/positions')
        .then(res => res.json())
        .then(positions => {
            //console.log(positions); 
            setBitmexPositions(f => positions.bitmex)
            setbybitPositions(f => positions.bybit)
        })
    }
    const getOrders = () => {
      fetch('/api/orders')
      .then(res => res.json())
      .then(orders => {
        //console.log(orders)
        setBitmexOrders(f => orders.bitmex)
        setBybitOrders(f => orders.bybit)
      })
    }
    const getAccount = (id) => {
        return accounts.find(element => element.id == id);
    }
    const measureRisk = (orders) => {
      orders.forEach(order => {
        if (order.ordType === 'Stop') {
          console.log(bitmexPositions)
          let position = bitmexPositions.find(element => element.symbol == order.symbol & element.account == order.account)
          console.log(position)
          console.log(order)
        }
      })
    }
    const riskBitmex = (position) => {
      //console.log(position)
      let side = position.currentQty > 0 ? 'Buy' : 'Sell'
      let risk = bitmexRisks.find(element => element.side === side & element.account === position.account & element.symbol === position.symbol)
      if (risk) {
        if (risk.stopPnLPct > 0) {
          return (<div class="d-flex justify-content-center">
          <span class='tx-success align-self-center'>{risk.protection === 'Full' ? <Octagon  /> : <AlertOctagon />}</span>
          <div class="mg-l-15">
            <span class="lh-5 mg-b-0 mg-l-5 tx-10 tx-normal">{risk.stopPnLPct.toFixed(2)} %</span>
            <span class="lh-5 d-block tx-color-03 tx-10 tx-normal">{(risk.stopPnL/100000000).toFixed(4)} BTC</span>
            </div>
          </div>)}
        if (risk.stopPnLPct <= -3 && risk.stopPnLPct > -5) {
          return (<div class="d-flex justify-content-center">
          <span class='tx-warning align-self-center'>{risk.protection === 'Full' ? <Octagon  /> : <AlertOctagon />}</span>
          <div class="mg-l-15">
            <span class="lh-5 mg-b-0 mg-l-5 tx-10 tx-normal">{risk.stopPnLPct.toFixed(2)} %</span>
            <span class="lh-5 d-block tx-color-03 tx-10 tx-normal">{(risk.stopPnL/100000000).toFixed(4)} BTC</span>
            </div>
          </div>)}
        if (risk.stopPnLPct <= -5 && risk.stopPnLPct > -7.5) {
          return (<div class="d-flex justify-content-center">
          <span class='tx-orange align-self-center'>{risk.protection === 'Full' ? <Octagon  /> : <AlertOctagon />}</span>
          <div class="mg-l-15">
            <span class="lh-5 mg-b-0 mg-l-5 tx-10 tx-normal">{risk.stopPnLPct.toFixed(2)} %</span>
            <span class="lh-5 d-block tx-color-03 tx-10 tx-normal">{(risk.stopPnL/100000000).toFixed(4)} BTC</span>
            </div>
          </div>)}
        if (risk.stopPnLPct <= -7.5) {
          return (<div class="d-flex justify-content-center">
          <span class='tx-danger align-self-center'>{risk.protection === 'Full' ? <Octagon  /> : <AlertOctagon />}</span>
          <div class="mg-l-15">
            <span class="lh-5 mg-b-0 mg-l-5 tx-10 tx-normal">{risk.stopPnLPct.toFixed(2)} %</span>
            <span class="lh-5 d-block tx-color-03 tx-10 tx-normal">{(risk.stopPnL/100000000).toFixed(4)} BTC</span>
            </div>
          </div>)
        }
        if (risk.protection === 'Full') {
          return (<div class="d-flex justify-content-center">
          <span class='align-self-center'><Octagon  /></span>
          <div class="mg-l-15">
            <span class="lh-5 mg-b-0 mg-l-5 tx-10 tx-normal">{risk.stopPnLPct.toFixed(2)} %</span>
            <span class="lh-5 d-block tx-color-03 tx-10 tx-normal">{(risk.stopPnL/100000000).toFixed(4)} BTC</span>
            </div>
          </div>)
        } else {
          return (<div class="d-flex justify-content-center">
          <span class='align-self-center'><AlertOctagon  /></span>
          <div class="mg-l-15">
            <span class="lh-5 mg-b-0 mg-l-5 tx-10 tx-normal">{risk.stopPnLPct.toFixed(2)} %</span>
            <span class="lh-5 d-block tx-color-03 tx-10 tx-normal">{(risk.stopPnL/100000000).toFixed(4)} BTC</span>
            </div>
          </div>)
        }
      } else {
        return (<span class="tx-danger"><XOctagon /></span>)
      }
    }
    const riskBybit = (position) => {
      //console.log(position)
      let risk = bybitRisks.find(element => element.side === position.side & element.user_id === position.user_id & element.symbol === position.symbol)
      if (risk) {
        if (risk.stopPnLPct > 0) {
          return (<div class="d-flex justify-content-center">
          <span class='tx-success align-self-center'>{risk.protection === 'Full' ? <Octagon  /> : <AlertOctagon />}</span>
          <div class="mg-l-15">
            <span class="lh-5 mg-b-0 mg-l-5 tx-10 tx-normal">{risk.stopPnLPct.toFixed(2)} %</span>
            <span class="lh-5 d-block tx-color-03 tx-10 tx-normal">{risk.stopPnL.toFixed(4)} {position.symbol.split('USD')[0]}</span>
            </div>
          </div>)}
        if (risk.stopPnLPct <= -3 && risk.stopPnLPct > -5) {
          return (<div class="d-flex justify-content-center">
          <span class='tx-warning align-self-center'>{risk.protection === 'Full' ? <Octagon  /> : <AlertOctagon />}</span>
          <div class="mg-l-15">
            <span class="lh-5 mg-b-0 mg-l-5 tx-10 tx-normal">{risk.stopPnLPct.toFixed(2)} %</span>
            <span class="lh-5 d-block tx-color-03 tx-10 tx-normal">{risk.stopPnL.toFixed(4)} {position.symbol.split('USD')[0]}</span>
            </div>
          </div>)}
        if (risk.stopPnLPct <= -5 && risk.stopPnLPct > -7.5) {
          return (<div class="d-flex justify-content-center">
          <span class='tx-orange align-self-center'>{risk.protection === 'Full' ? <Octagon  /> : <AlertOctagon />}</span>
          <div class="mg-l-15">
            <span class="lh-5 mg-b-0 mg-l-5 tx-10 tx-normal">{risk.stopPnLPct.toFixed(2)} %</span>
            <span class="lh-5 d-block tx-color-03 tx-10 tx-normal">{risk.stopPnL.toFixed(4)} {position.symbol.split('USD')[0]}</span>
            </div>
          </div>)}
        if (risk.stopPnLPct <= -7.5) {
          return (<div class="d-flex justify-content-center">
          <span class='tx-danger align-self-center'>{risk.protection === 'Full' ? <Octagon  /> : <AlertOctagon />}</span>
          <div class="mg-l-15">
            <span class="lh-5 mg-b-0 mg-l-5 tx-10 tx-normal">{risk.stopPnLPct.toFixed(2)} %</span>
            <span class="lh-5 d-block tx-color-03 tx-10 tx-normal">{risk.stopPnL.toFixed(4)} {position.symbol.split('USD')[0]}</span>
            </div>
          </div>)
        }
        if (risk.protection === 'Full') {
          return (<div class="d-flex justify-content-center">
          <span class='align-self-center'><Octagon  /></span>
          <div class="mg-l-15">
            <span class="lh-5 mg-b-0 mg-l-5 tx-10 tx-normal">{risk.stopPnLPct.toFixed(2)} %</span>
            <span class="lh-5 d-block tx-color-03 tx-10 tx-normal">{risk.stopPnL.toFixed(4)} {position.symbol.split('USD')[0]}</span>
            </div>
          </div>)
        } else {
          return (<div class="d-flex justify-content-center">
          <span class='align-self-center'><AlertOctagon  /></span>
          <div class="mg-l-15">
            <span class="lh-5 mg-b-0 mg-l-5 tx-10 tx-normal">{risk.stopPnLPct.toFixed(2)} %</span>
            <span class="lh-5 d-block tx-color-03 tx-10 tx-normal">{risk.stopPnL.toFixed(4)} {position.symbol.split('USD')[0]}</span>
            </div>
          </div>)
        }
      } else {
        return (<span class="tx-danger"><XOctagon /></span>)
      }
    }
    const updateBitmexOrders = (orders) => {
      setBitmexOrders(odrs => {
        let lastorders = odrs
        let newOrders = []
        orders.forEach(order => {
          let index = lastorders.findIndex(e => e.orderID === order.orderID)
          if (index === -1) {
            newOrders.push(order)
          }else {
            lastorders[index] = order
          }
        })
        return [...lastorders, ...newOrders]
      })
    }
    const updateBybitOrders = (orders) => {
      //console.log('bybit order update',orders)
      setBybitOrders(odrs => {
        let lastorders = odrs
        let newOrders = []
        orders.forEach(order => {
          let index = lastorders.findIndex(e => e.stop_order_id === order.stop_order_id)
          if (index === -1) {
            //console.log('new order', order)
            newOrders.push(order)
          }else {
            //console.log('update previous', order)
            lastorders[index] = order
          }
        })
        return [...lastorders, ...newOrders]
      })
    }
    useEffect(() => {
      let bybitR = []
      bybitPositions.filter(p => p.size != 0).forEach(position => {
        let orderSide = position.side === 'Sell' ? 'Buy' : 'Sell'
        let orders = bybitOrders.filter(element => element.side === orderSide && (element.stop_order_type === 'Stop' || element.stop_order_type === 'StopLoss') && element.stop_order_status === 'Untriggered' && element.user_id === position.user_id && element.symbol === position.symbol)
        let value = 0, qty = 0
        if (orders.length > 0) {
          let risk = {}
          //console.log(position)
          //console.log(orders)
          orders.forEach(order => {
            if (qty === Math.abs(position.size)) {
              return
            }
            if (qty + order.qty <= Math.abs(position.size)) {
              value += order.qty/order.stop_px
              qty +=order.qty
            } else {
              let q = (qty + order.qty) - Math.abs(position.size)
              value += q/order.stop_px
              qty += q
            }
          })
          risk.stop = qty/value
          if (position.side === 'Buy') {
            risk.stopPrice = risk.stop - (risk.stop * 0.00175)
          } else {
            risk.stopPrice = risk.stop + (risk.stop * 0.00175)
          }
          risk.stopValue = (1/risk.stopPrice) * position.size
          risk.entryValue= (1/position.entry_price) * position.size
          if (position.side === 'Buy'){
            //(ETHUSD Exit Price - ETHUSD Entry Price) * Bitcoin Multiplier * # Contracts
            risk.stopPnL = (1/position.entry_price - 1/risk.stopPrice) * position.size
            //risk.stopPnL =  risk.entryValue - risk.stopValue
          } else {
            risk.stopPnL = (1/risk.stopPrice - 1/position.entry_price) * position.size
            //risk.stopPnL =  risk.entryValue - risk.stopValue
          }
          risk.stopPnLPct = (risk.stopPnL/position.wallet_balance) * 100
          risk.user_id = position.user_id
          risk.symbol = position.symbol
          risk.qty = qty
          risk.side = position.side
          risk.protection = position.size === qty ? 'Full' : 'Partial'
          bybitR.push(risk)
          //console.log(orders, position, risk)
        }
      })
      setBybitRisks(f =>bybitR)
    }, [bybitOrders])
    useEffect(() => {
      //console.log(bitmexPositions)
      //console.log(bitmexOrders)
      let bitmexR = []
      bitmexPositions.filter(p => p.currentQty != 0).forEach(position => {
        let orderSide = position.currentQty > 0 ? 'Sell' : 'Buy'
        let wallet = bitmexWallets.find(wallet => wallet.account === position.account)
        let orders = bitmexOrders.filter(element => element.ordStatus=== 'New' & element.ordType === 'Stop' & element.side ===  orderSide & element.symbol === position.symbol & element.account === position.account)
        let value = 0, qty = 0
        if (orders.length > 0) {
          let risk = {}
          //console.log(position)
          //console.log(orders)
          orders.forEach(order => {
            if (qty === Math.abs(position.currentQty)) {
              return
            }
            if (qty + order.orderQty <= Math.abs(position.currentQty)) {
              value += order.orderQty/order.stopPx
              qty +=order.orderQty
            } else {
              let q = (qty + order.orderQty) - Math.abs(position.currentQty)
              value += q/order.stopPx
              qty += q
            }
          })
          risk.stop = qty/value

          //slippage and exchane fee
          if (position.currentQty > 0) {
            risk.stopPrice = risk.stop - (risk.stop * 0.00175)
          } else {
            risk.stopPrice = risk.stop + (risk.stop * 0.00175)
          }
          if (position.symbol.includes('ETH')) {
            let mtl = 0.000001
            risk.stopValue = risk.stopPrice * mtl * Math.abs(position.currentQty)
            risk.entryValue= position.avgEntryPrice * mtl * Math.abs(position.currentQty)
            if (position.currentQty > 0){
              //(ETHUSD Exit Price - ETHUSD Entry Price) * Bitcoin Multiplier * # Contracts
              risk.stopPnL = (risk.stopValue - risk.entryValue) * 100000000
            } else {
              risk.stopPnL =  (risk.entryValue - risk.stopValue) * 100000000
            }
            risk.stopPnLPct = (risk.stopPnL/wallet.walletBalance) * 100
          }
          if (position.symbol.includes('ADA') || position.symbol.includes('EOS') || position.symbol.includes('TRX')) {
            let mtl = 1.00
            risk.stopValue = risk.stopPrice * mtl * Math.abs(position.currentQty)
            risk.entryValue= position.avgEntryPrice * mtl * Math.abs(position.currentQty)
            if (position.currentQty > 0){
              //(ETHUSD Exit Price - ETHUSD Entry Price) * Bitcoin Multiplier * # Contracts
              risk.stopPnL = (risk.stopValue - risk.entryValue) * 100000000
            } else {
              risk.stopPnL =  (risk.entryValue - risk.stopValue) * 100000000
            }
            risk.stopPnLPct = (risk.stopPnL/wallet.walletBalance) * 100
          }
          if (position.symbol.includes('BCH')) {
            let mtl = 0.000001
            risk.stopValue = risk.stopPrice * mtl * Math.abs(position.currentQty)
            risk.entryValue= position.avgEntryPrice * mtl * Math.abs(position.currentQty)
            if (position.currentQty > 0){
              //(ETHUSD Exit Price - ETHUSD Entry Price) * Bitcoin Multiplier * # Contracts
              risk.stopPnL = (risk.stopValue - risk.entryValue) * 100000000
            } else {
              risk.stopPnL =  (risk.entryValue - risk.stopValue) * 100000000
            }
            risk.stopPnLPct = (risk.stopPnL/wallet.walletBalance) * 100
          }
          if (position.symbol.includes('LTC')) {
            let mtl = 0.000002
            risk.stopValue = risk.stopPrice * mtl * Math.abs(position.currentQty)
            risk.entryValue= position.avgEntryPrice * mtl * Math.abs(position.currentQty)
            if (position.currentQty > 0){
              //(ETHUSD Exit Price - ETHUSD Entry Price) * Bitcoin Multiplier * # Contracts
              risk.stopPnL = (risk.stopValue - risk.entryValue) * 100000000
            } else {
              risk.stopPnL =  (risk.entryValue - risk.stopValue) * 100000000
            }
            risk.stopPnLPct = (risk.stopPnL/wallet.walletBalance) * 100
          }
          if (position.symbol.includes('XRP')) {
            let mtl = 0.0002
            risk.stopValue = risk.stopPrice * mtl * Math.abs(position.currentQty)
            risk.entryValue= position.avgEntryPrice * mtl * Math.abs(position.currentQty)
            if (position.currentQty > 0){
              //(ETHUSD Exit Price - ETHUSD Entry Price) * Bitcoin Multiplier * # Contracts
              risk.stopPnL = (risk.stopValue - risk.entryValue) * 100000000
            } else {
              risk.stopPnL =  (risk.entryValue - risk.stopValue) * 100000000
            }
            risk.stopPnLPct = (risk.stopPnL/wallet.walletBalance) * 100
          }
          if (position.symbol.includes('XBT')) {
            let mtl = 1
            risk.stopValue = risk.stopPrice * mtl * Math.abs(position.currentQty)
            risk.entryValue= position.avgEntryPrice * mtl * Math.abs(position.currentQty)
            if (position.currentQty > 0){
              //(ETHUSD Exit Price - ETHUSD Entry Price) * Bitcoin Multiplier * # Contracts
              //risk.stopPnL = (1/position.avgEntryPrice - 1/risk.stopPrice) * mtl * Math.abs(position.currentQty)
              risk.stopPnL = risk.stopValue - risk.entryValue
            } else {
              //risk.stopPnL = (1/risk.stopPrice - 1/position.avgEntryPrice) * mtl * Math.abs(position.currentQty)
              risk.stopPnL =  risk.entryValue - risk.stopValue
            }
            risk.stopPnLPct = (risk.stopPnL/wallet.walletBalance) * 100
          }
          risk.account = position.account
          risk.symbol = position.symbol
          risk.qty = qty
          risk.side = position.currentQty > 0 ? 'Buy' : 'Sell'
          risk.protection = Math.abs(position.currentQty) === qty ? 'Full' : 'Partial'
          bitmexR.push(risk)
          //console.log(wallet, risk, position, orders)
        }
      })
      setBitmexRisks(f =>bitmexR)
      
    }, [bitmexOrders])

    useEffect(() => {
        getPositions()
        getOrders()
        socket.on('bitmex_position', positions => {
            setBitmexPositions(bp => {
                let notfound = []
                let ps = bp
                positions.forEach(element => {
                    let found = false
                    let i
                    for (i = 0; i < ps.length; i++) {
                        if (ps[i].account === element.account & ps[i].symbol === element.symbol) {
                            found = true
                            ps[i] = element
                        }
                      } 
                    if (found === false) {
                        notfound.push(element)
                    }
                }); 
                return [...ps, ...notfound]
            })
        })
        socket.on('bybit_position', position => {
            setbybitPositions(f => {
                let ps = f;
                let found = false
                let i
                let id 
                for (i = 0; i < ps.length; i++) {
                    if (ps[i].user_id === position.user_id & ps[i].symbol === position.symbol) {
                      if (position.mode == 'BothSide') {
                        if (position.side === ps[i].side) {
                          ps[i] = position
                          found = true
                          break
                        }
                      } else {
                        found = true
                        ps[i] = position
                        break
                      }
                    }
                  }
                if (found) {
                    return ps
                } else {
                    return [...ps, position]
                }
                

            })
        })
        socket.on('bitmex_order', updateBitmexOrders)
        socket.on('bybit_stop_order', updateBybitOrders)
        return () => {
            socket.off('bybit_position')
            socket.off('bitmex_position')
            socket.off('bitmex_order')
            socket.off('bybit_stop_order')
        }

    }, [])

    const BitmexPositionRows = bitmexPositions.filter(p => p.currentQty != 0).map(position => (<tr>
        <td><img src={bitmeximg} style={{height: '20px'}} alt="Bitmex" /></td>
        <td class="tx-medium tx-color-02"><Link to={`/bitmex/${position.account}`}>{getAccount(position.account).title}</Link></td>
        <td class="tx-color-03 tx-normal">{position.symbol === 'XBTUSD' ? 'BTCUSD' : position.symbol}</td>
        <td className={position.currentQty > 0 ? "text-center tx-success" : "text-center tx-danger"}>{position.currentQty}</td>
      <td class="text-center tx-teal">{position.avgEntryPrice}</td>
      <td class="tx-medium text-center" width="10%"><h6 class="tx-rubik mg-b-1">{get_price(position.symbol)}</h6> </td>
      <td class="tx-medium text-center">{(position.unrealisedPnl/100000000).toFixed(4)} XBT <span className={position.unrealisedRoePcnt > 0 ? 'mg-l-5 tx-10 tx-normal tx-success': 'mg-l-5 tx-10 tx-normal tx-danger'}><i class={position.unrealisedRoePcnt > 0 ? "icon ion-md-arrow-up" : "icon ion-md-arrow-down"}></i> {position.unrealisedRoePcnt.toFixed(2)}%</span><p class="tx-10 tx-color-03 mg-b-0">${((position.unrealisedPnl/100000000) * XBT).toFixed(2) } USD</p></td>
      <td class="tx-medium text-center">{riskBitmex(position)}</td>
      </tr>))
    const BybitpositionRows = bybitPositions.filter(p => p.size != 0).map(position => (<tr>
        <td><img src={bybitimg} style={{height: '20px'}} alt="Bybit" /></td>
        <td class="tx-medium tx-color-02"><Link to={`/bybit/${position.user_id}`}>{getAccount(position.user_id).title}</Link></td>
        <td class="tx-color-03 tx-normal"><h6 class="tx-rubik mg-b-1">{position.symbol}</h6></td>
        <td className={position.side == 'Buy' ? "text-center tx-success" : "text-center tx-danger"}>{position.side == 'Buy' ? position.size : -position.size}</td>
      <td class="text-center tx-teal">{(+position.entry_price).toFixed(4)}</td>
      <td class="tx-medium text-center" width="10%"><h6 class="tx-rubik mg-b-1">{get_price(position.symbol) }</h6> </td>
      <td class="tx-medium text-center">{calculatePnl(position).toFixed(4)} {position.symbol.split('USD')[0]} <span className={(position.side == 'Buy' && position.entry_price < get_price(position.symbol) ) || (position.side == 'Sell' && position.entry_price > get_price(position.symbol) ) ? 'mg-l-5 tx-10 tx-normal tx-success': 'mg-l-5 tx-10 tx-normal tx-danger'}><i className={(position.side == 'Buy' && position.entry_price < get_price(position.symbol) ) || (position.side == 'Sell' && position.entry_price > get_price(position.symbol) ) ? "icon ion-md-arrow-up" : "icon ion-md-arrow-down"}></i> {calculatePnlPcnt(position)}%</span><p class="tx-10 tx-color-03 mg-b-0">${(calculatePnl(position)*get_price(position.symbol) ).toFixed(2)} USD</p></td>
      <td class="tx-medium text-center">{riskBybit(position)}</td>
      </tr>))
    
    return (<>
    <div class="row">
    <div class="col-lg-12 mg-t-10">
    <div class="card mg-b-10">
      <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
        <div>
          <h3 class="mg-b-5">Positions</h3>
        </div>
      </div>
      <div class="card-body">
      <div class="table-responsive pd-y-15">
        <table class="table table-dashboard mg-b-0">
          <thead>
            <tr>
              <th class=""></th>
              <th class="">Account</th>
              <th>Symbol</th>
              <th class="text-center">Size</th>
              <th class="text-center">Entry</th>
              <th class="text-center">Price</th>
              <th class="text-center">UnRealised PNL</th>
              <th class="text-center">Risk</th>
            </tr>
          </thead>
          <tbody>
            {BitmexPositionRows}
            {BybitpositionRows}
          </tbody>
        </table>
      </div>
      </div>
    </div>
  </div>
  </div>
  </>
)
}