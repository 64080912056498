import React from 'react';
import {Link} from 'react-router-dom'
import {useGlobalState} from '../../store'
import { useCookies } from 'react-cookie';


import Pantheon from '../../images/pantheon.png'
import PantheonDark from '../../images/pantheon_dark.png'

export default function Logo () {
    const [client] = useGlobalState('client')
    const [cookies] = useCookies(['theme']);
    if (client == 'pantheon') {
        return (<div className="navbar-brand">
            {cookies.theme === undefined && 
            <Link to="/" className="df-logo"><img src={Pantheon} style={{height: '40px'}} alt="Pantheon" /></Link> 
            }
            {cookies.theme === 'dark' &&
            <Link to="/" className="df-logo"><img src={PantheonDark} style={{height: '40px'}} alt="Pantheon" /></Link> 
            }
            {cookies.theme === 'light' &&
            <Link to="/" className="df-logo"><img src={Pantheon} style={{height: '40px'}} alt="Pantheon" /></Link> 
            }
            
        </div>)
    }
    return (<div className="navbar-brand">
    <Link to="/" className="df-logo">crypto<span>lytics</span></Link>
    </div>)
}