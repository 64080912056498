import React, {useEffect, useState}from 'react';
import {Link} from 'react-router-dom'
import { Moon, Sun, Settings, Menu} from 'react-feather';
import { useCookies } from 'react-cookie';
import {useGlobalState, get_price} from '../../store'
import Logo from './Logo'

export default function Nav () {
    const [cookies, setCookie] = useCookies(['theme']);
    const [accounts, setAccounts] = useGlobalState('accounts')
    const [logged_in, setlogged_in] = useGlobalState('logged_in')
    const [user] = useGlobalState('user')
    const [tickers] = useGlobalState('tickers')
    const logout = async () => {
      const fetchResult = await fetch('api/logout')
      if (fetchResult.ok) {
        setlogged_in(false)
      } else {
        setlogged_in(false)
      }
    }
    
    useEffect(() => {
    }, [])
const bitmexAccounts = accounts.filter(account =>account.exchange == 'BITMEX').map(account => (<li key={account.id} class="nav-sub-item"><Link to={`/bitmex/${account.id}`} class="nav-sub-link"> <i data-feather="user"></i> {account.title}</Link></li>))
const bybitAccounts = accounts.filter(account =>account.exchange == 'BYBIT').map(account => (<li key={account.id} class="nav-sub-item"><Link to={`/bybit/${account.id}`} class="nav-sub-link"> <i data-feather="user"></i> {account.title}</Link></li>))
const ftxusAccounts = accounts.filter(account =>account.exchange == 'FTXUS').map(account => (<li key={account.id} class="nav-sub-item"><Link to={`/ftxus/${account.id}`} class="nav-sub-link"> <i data-feather="user"></i> {account.title}</Link></li>))
    return (<header className="navbar navbar-header navbar-header-fixed">
    <a href="" id="mainMenuOpen" class="burger-menu"><i data-feather="menu"></i></a>
    <Logo/>
    <div id="navbarMenu" class="navbar-menu-wrapper"> 
        <div className="navbar-menu-header">
        <Link to='/' className="df-logo">crypto<span>lytics</span></Link>
        <a id="mainMenuClose" href=""><i data-feather="x"></i></a> </div>
    <ul className="nav navbar-menu">
    <li class="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>
    <li class="nav-item"><Link to='/' class="nav-link"><i data-feather="users"></i> Dashboard</Link></li>
    <li class="nav-item"><Link to='/performance' class="nav-link"><i data-feather="bar-chart"></i> Performance</Link></li>
    <li class="nav-item with-sub">
          <a class="nav-link" href="" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i data-feather="users"></i> Accounts
        </a>
        <ul class="navbar-menu-sub" aria-labelledby="navbarDropdownMenuLink">
            {bitmexAccounts.length > 0 && <>
            <li class="nav-label mg-t-20">Bitmex</li>
              {bitmexAccounts}
            </>}
            {bybitAccounts.length > 0 && <>
            <li class="nav-label mg-t-20">ByBit</li>
              {bybitAccounts}
            </>}
            {ftxusAccounts.length > 0 && <>
                <li class="nav-label mg-t-20">FTXUS</li>
                  {ftxusAccounts}
            </>}
        </ul>
      </li>
    </ul> 
            
    </div>
    <div className="navbar-right">
      <span className="tx-20 nav-link d-none d-md-block tx-rubik">
        <i class=" cf cf-btc tx-orange"><span class="tx-color-02 mg-l-5 tx-rubik">{get_price('BTC').toFixed(1)}</span> </i>
      </span>
      <span className="tx-20 nav-link tx-color-02 d-none d-md-block tx-rubik">
        <i class=" cf cf-eth"><span class="tx-color-02 mg-l-5 tx-rubik">{get_price('ETH').toFixed(2)}</span> </i>
      </span>
    <span className="nav-link">
    {cookies.theme === undefined && 
        <Moon onClick={e => setCookie('theme', 'dark')} size={20}/>
    }
    {cookies.theme === 'dark' &&
    <Sun color="#fff" onClick={e => setCookie('theme', 'light')} size={18}/>
}
    {cookies.theme === 'light' &&
    <Moon onClick={e => setCookie('theme', 'dark')} size={20}/>
    }
    </span>
    <Link to='/settings' className="nav-link"><Settings size={20}/></Link>
    <div class="dropdown dropdown-profile">
          <a href="" class="dropdown-link" data-toggle="dropdown" data-display="static">
            <div class="avatar avatar-sm avatar-online"><span class="avatar-initial rounded-circle">{user.slice(0, 2)}</span></div>
          </a>
          <div class="dropdown-menu dropdown-menu-right tx-13">
            <h6 class="tx-semibold mg-b-5">{user}</h6>

            <div class="dropdown-divider"></div>
            <a href="#" onClick={logout} class="dropdown-item"><i data-feather="log-out"></i>Sign Out</a>
          </div>
        </div>
    </div> 
    </header>
    )
}