import React, {useEffect, useState} from 'react';
import { AreaChart, XAxis,Tooltip, Area, Line, ResponsiveContainer, YAxis, LineChart } from 'recharts'
import {socket} from '../../config'
import {useGlobalState} from '../../store'
import moment from 'moment'
export default function Chart() {
    const [accounts, setAccounts] = useGlobalState('accounts')
    const [transactions, setTransactions] = useState([])
    const [filteredTransactions, setFilteredTransactions] = useState([])
    const [filter, setFilter] = useState(null)


    const getAccount = (id) => {
        return accounts.find(element => element.id === id);
    }
    const ft = (k) => {
        let fts = transactions.filter(transaction => {
          if (k === 'a') {
            return true
          }
          let dt = new Date(transaction.date)
          let now = new Date()
          if (k === 'y') {
            now.setDate(now.getDate() - 365);
            return dt > now;
          }
          if (k === '6m') {
            now.setDate(now.getDate() - 182);
            return dt > now;
          }
          if (k === '3m') {
            now.setDate(now.getDate() - 90);
            return dt > now;
          }
          if (k === 'm') {
            now.setDate(now.getDate() - 30);
            return dt > now;
          }
    
        })
        setFilteredTransactions(f => fts)
        
      }

    const getData = () => {
        fetch('api/accounts_transactions')
        .then(res => res.json())
        .then(transactions => {
            //console.log(transactions)
            setTransactions(f => transactions)
            setFilter('a')
        })
    }
    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        ft(filter)
      }, [filter])
    
    return (<div class="row">
    <div class="col-lg-12 mg-t-10">
    <div class="card card-crypto">
      <div class="card-header pd-y-8 d-sm-flex align-items-center justify-content-between">
        <nav class="nav nav-line">
          <span onClick={() => setFilter('m')} style={{"cursor": "pointer"}} className={filter === 'm' ? "nav-link active" : 'nav-link'}>Month</span>
          <span onClick={() => setFilter('3m')} style={{"cursor": "pointer"}} className={filter === '3m' ? "nav-link active" : 'nav-link'}>3 Months</span>
          <span onClick={() => setFilter('6m')} style={{"cursor": "pointer"}} className={filter === '6m' ? "nav-link active" : 'nav-link'}>6 Months</span>
          <span onClick={() => setFilter('y')} style={{"cursor": "pointer"}}  className={filter === 'y' ? "nav-link active" : 'nav-link'}>Year</span>
          <span onClick={() => setFilter('a')} style={{"cursor": "pointer"}} className={filter === 'a' ? "nav-link active" : 'nav-link'}>All</span>
        </nav>
        
        
      </div>
      <div class="card-body pd-10 pd-sm-20">
        <div class="chart-eleven">
        <ResponsiveContainer width="100%" height={600}>
        <AreaChart  data={filteredTransactions}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#0168fa" stopOpacity={0.2}/>
                      <stop offset="95%" stopColor="#0168fa" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis tick={{stroke: '#8392a5', strokeWidth: 0.01}} interval={'preserveStart'} minTickGap={50} axisLine={false} tickLine={false} dataKey="date" tickFormatter={xAxsisFormat}  />
          <YAxis tick={{stroke: '#8392a5', strokeWidth: 0.01}} tickLine={false} axisLine={false} />
          <Tooltip content={<CustomTooltip />}/>
        <Area type="monotone" dataKey="portfolio" stroke="#0168fa" fillOpacity={1} fill="url(#colorPv)" />      
        </AreaChart>
        </ResponsiveContainer>
        </div>
      </div>
      </div>
  </div>
  </div>)
}

const xAxsisFormat = (ts) => {
    let dt = new Date(ts)
    return moment(dt).format('DD MMM')
  }
  

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      if (payload[0] == null) {
        return null
      }
      let dt = new Date(label)
      return (
        <div className="custom-tooltip">
          <h5 class="tx-normal tx-rubik tx-spacing--2 mg-b-0"><i class="cf cf-btc"></i>{(payload[0].value).toFixed(4)}</h5>
          <p className="tx-color-03">{moment(dt).format('DD MMM, YY')}</p>
        </div>
      );
    }
  
    return null;
  };

