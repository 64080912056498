import React, {useEffect, useState} from 'react';
import { Trash2 } from 'react-feather'
import {Link, Navigate} from 'react-router-dom'

export default function Settings() {


    const [accounts, setAccounts] = useState(null)

    const getAccounts = () => {
      fetch('/api/accounts')
        .then(res => res.json())
        .then(data => {
            console.log(data)
            setAccounts(f => data)
        })
    }
    const deleteAccount = (id) => {
      fetch(`api/account/${id}`, {method: 'DELETE'})
      .then(res => {
        getAccounts()
      })
    }
    useEffect(() => {
        getAccounts()
    }, [])

    
    if (accounts == null) {
        return (<div>Loading</div>)
    }
    const accountsRows = accounts.map(account =><tr key={account.api_key}>
        <td class="tx-semibold">{account.id}</td>
        <td class="tx-semibold">{account.title}</td>
        <td class="tx-medium ">{account.exchange}</td>
        <td class="tx-medium">{account.api_key}</td>
        <td class="tx-medium"><Trash2 onClick={e => deleteAccount(account.id)} /></td>
      </tr>)
    return(
        <>
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                <li class="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Accounts</li>
              </ol>
            </nav>
            <h4 class="mg-b-0 tx-spacing--1">Accounts</h4>
          </div>
          <div class="d-none d-md-block">
            <Link to="/new-account" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"> Add new Account</Link>
          </div>
        </div>
        <div class="col-lg-12 col-xl-12 mg-t-10">
            <div class="card card-body mg-b-10">
              {accounts.length === 0 && (<div>No account found!</div>)}
              {accounts.length > 0 && (<div class="table-responsive">
                <table class="table table-hover mg-b-0">
                  <thead>
                    <tr>
                    <td>ID</td>
                      <th>TITLE</th>
                      <th class="">EXCHANGE</th>
                      
                      <th class="">API_KEY</th>
                      <th class="">DELETE</th>
                    </tr>
                  </thead>
                  <tbody>
                  {accountsRows}
                  </tbody>
                </table>
                </div>
                )}
                </div>
            </div>
        </>
    
    )

}