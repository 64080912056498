import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import BitmexSummary from './bitmexSummary'
import BybitSummary from './bybitSummary'
import Positions from './positions'
import Wallets from './wallets'
import Chart from './Chart'
import Portfolio from './portfolio'
import {useGlobalState} from '../../store'
export default function Dashboard() {


    const [accounts, setAccounts] = useGlobalState('accounts')


    useEffect(() => {
    }, [])

    
    if (accounts == null) {
        return (<div>Loading</div>)
    }
    if (accounts.length == 0) {
        return (<>
            <div>
                <h4 class="mg-b-0 tx-spacing--1">Dashboard</h4>
            </div>
            <div class="row row-xs mg-t-10">
                <div class='card card-body'>
                <h6 class="mg-b-0 tx-spacing--1">No account found! please <Link to={'/new-account'}> add an account </Link></h6>
                </div>
            </div>
            </>)
    }
    const accountsCards = accounts.map(account => account.exchange == 'BITMEX' ? <BitmexSummary key={account.id} account={account} /> : <BybitSummary key={account.id} account={account} />)
    return (
        <>
        <div>
            <h2 class="mg-b-20 tx-spacing--1">Dashboard</h2>
        </div>
        <Portfolio />
        <Wallets />
        <Positions />
        <div class="row row-xs mg-t-8">
            
        </div>
        </>
    )
}