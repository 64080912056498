import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {socket} from '../../config'
import {useGlobalState, get_price} from '../../store'
import bitmeximg from '../../images/bitmex.png'
import bybitimg from '../../images/bybit.png'
import ftxusimg from '../../images/ftxus.png'

export default function Wallets() {
    const [accounts, setAccounts] = useGlobalState('accounts')
    const [bitmexWallets, setBitmexWallets] = useGlobalState('bitmexWallets')
    const [bybitWallets, setBybitWallets] = useGlobalState('bybitWallets')
    const [ftxusWallets, setFtxusWallets] = useGlobalState('ftxusWallets')
    const [portfolio, setPortfolio] = useGlobalState('portfolio')
    
    const getAccount = (id) => {
        return accounts.find(element => element.id === id);
    }
    function updatePortfolio() {
        let wallets = []
        
        bitmexWallets.forEach(wallet => {
            let index = wallets.findIndex(w => w.currency === 'BTC')
            if (index > -1) {
                wallets[index].wallet_balance += wallet.walletBalance/100000000
                wallets[index].margin_balance += wallet.marginBalance/100000000
                wallets[index].unrealised_pnl += wallet.unrealisedPnl/100000000
                wallets[index].realised_pnl += wallet.realisedPnl/100000000
            } else {
                wallets.push(
                    {
                        wallet_balance: wallet.walletBalance/100000000,
                        margin_balance : wallet.marginBalance/100000000,
                        unrealised_pnl : wallet.unrealisedPnl/100000000,
                        realised_pnl : wallet.realisedPnl/100000000,
                        currency : 'BTC'
                    }
                )
            }
            
        })

        
        bybitWallets.forEach(wallet => {
            let index = wallets.findIndex(w => w.currency === wallet.currency)
            if (index > -1) {
                wallets[index].wallet_balance += wallet.wallet_balance
                wallets[index].margin_balance += wallet.equity
                wallets[index].unrealised_pnl += wallet.unrealised_pnl
                wallets[index].realised_pnl += wallet.realised_pnl
            } else {
                wallets.push({
                    currency: wallet.currency,
                    wallet_balance: wallet.wallet_balance,
                    margin_balance: wallet.equity,
                    unrealised_pnl: wallet.unrealised_pnl,
                    realised_pnl: wallet.realised_pnl
                })
            }
        })
        ftxusWallets.forEach(wallet => {
            let index = wallets.findIndex(w => w.currency === wallet.currency)
            if (index > -1) {
                wallets[index].wallet_balance += wallet.wallet_balance
                wallets[index].margin_balance += wallet.margin_balance
                wallets[index].unrealised_pnl += wallet.unrealised_pnl
                wallets[index].realised_pnl += wallet.realised_pnl
            } else {
                wallets.push({
                    currency: wallet.currency,
                    wallet_balance: wallet.wallet_balance,
                    margin_balance: wallet.margin_balance,
                    unrealised_pnl: wallet.unrealised_pnl,
                    realised_pnl: wallet.realised_pnl
                })
            }
        })
        wallets.sort(function(a, b){return (b.wallet_balance * get_price(b.currency))- (a.wallet_balance* get_price(a.currency))})
        setPortfolio(f => wallets)
    }
    const getWallets = () => {
        fetch('/api/wallets')
        .then(res => res.json())
        .then(wallets => {
            //console.log(wallets)
            setBitmexWallets(f => wallets.bitmex)
            setBybitWallets(f => wallets.bybit)
            setFtxusWallets(f => wallets.ftxus)
            
        })
    }
    useEffect(() => {
        updatePortfolio()
    }, [bitmexWallets, bybitWallets, ftxusWallets])


    const bitmexRows = bitmexWallets.map(row => <tr>
        <td><img src={bitmeximg} style={{height: '20px'}} alt="Bitmex" /></td>
        <td class="tx-medium tx-color-02"><Link to={`/bitmex/${row.account}`}>{getAccount(row.account).title}</Link></td>
        <td class="tx-medium text-center">{(row.walletBalance/100000000).toFixed(4)} XBT<p class="tx-10 tx-color-03 mg-b-0">${((row.walletBalance/100000000)* get_price('BTC')).toFixed(2)} USD</p></td>
        <td class="tx-medium text-center"><span class='tx-teal'>{(row.marginBalance/100000000).toFixed(4)}</span> XBT<p class="tx-10 tx-color-03 mg-b-0">${((row.marginBalance/100000000)* get_price('BTC')).toFixed(2)} USD</p></td>
        <td class="tx-medium text-center"><span class={row.realisedPnl > 0? 'tx-success' : 'tx-danger'}>{(row.realisedPnl/100000000).toFixed(4)}</span> XBT <p class="tx-10 tx-color-03 mg-b-0">${((row.realisedPnl/100000000)* get_price('BTC')).toFixed(2)} USD</p></td>
        <td class="tx-medium text-center"><span class={row.unrealisedPnl > 0? 'tx-success' : 'tx-danger'}>{(row.unrealisedPnl/100000000).toFixed(4)}</span> XBT <p class="tx-10 tx-color-03 mg-b-0">${((row.unrealisedPnl/100000000)* get_price('BTC')).toFixed(2)} USD</p></td>
        </tr>)
    const bybitRows = bybitWallets.filter(w => w.wallet_balance * get_price(w.currency) > 20).map(row => <tr>
        <td><img src={bybitimg} style={{height: '20px'}} alt="Bybit" /></td>
        <td class="tx-medium tx-color-02"><Link to={`/bybit/${row.user_id}`}>{getAccount(row.user_id).title}</Link></td>
        <td class="tx-medium text-center"><h6 class="tx-rubik mg-b-1">{(row.wallet_balance).toFixed(4)} {row.currency}</h6><p class="tx-10 tx-color-03 mg-b-0">${((row.wallet_balance)* get_price(row.currency)).toFixed(2)} USD</p></td>
        <td class="tx-medium text-center"><span class='tx-teal'>{(row.equity).toFixed(4)}</span> {row.currency}<p class="tx-10 tx-color-03 mg-b-0">${((+row.equity)* get_price(row.currency)).toFixed(2)} USD</p></td>
        <td class="tx-medium text-center"><span class={row.realised_pnl > 0? 'tx-success' : 'tx-danger'}>{(row.realised_pnl).toFixed(4)}</span> {row.currency} <p class="tx-10 tx-color-03 mg-b-0">${((row.realised_pnl)* get_price(row.currency)).toFixed(2)} USD</p></td>
        <td class="tx-medium text-center"><span class={row.unrealised_pnl > 0? 'tx-success' : 'tx-danger'}>{(row.unrealised_pnl).toFixed(4)}</span> {row.currency} <p class="tx-10 tx-color-03 mg-b-0">${((row.unrealised_pnl)* get_price(row.currency)).toFixed(2)} USD</p></td>
        </tr>)
    const ftxusRows = ftxusWallets.filter(w => w.wallet_balance * get_price(w.currency) > 20).map(row => <tr>
        <td><img src={ftxusimg} style={{height: '20px'}} alt="FtxUS" /></td>
        <td class="tx-medium tx-color-02"><Link to={`/ftxus/${row.user_id}`}>{getAccount(row.user_id).title}</Link></td>
        <td class="tx-medium text-center"><h6 class="tx-rubik mg-b-1">{(row.wallet_balance).toFixed(4)} {row.currency}</h6><p class="tx-10 tx-color-03 mg-b-0">${((row.wallet_balance)* get_price(row.currency)).toFixed(2)} USD</p></td>
        <td class="tx-medium text-center"><span class='tx-teal'>{(row.margin_balance).toFixed(4)}</span> {row.currency}<p class="tx-10 tx-color-03 mg-b-0">${((+row.margin_balance)* get_price(row.currency)).toFixed(2)} USD</p></td>
        <td class="tx-medium text-center"><span class={row.realised_pnl > 0? 'tx-success' : 'tx-danger'}>{(row.realised_pnl).toFixed(4)}</span> {row.currency} <p class="tx-10 tx-color-03 mg-b-0">${((row.realised_pnl)* get_price(row.currency)).toFixed(2)} USD</p></td>
        <td class="tx-medium text-center"><span class={row.unrealised_pnl > 0? 'tx-success' : 'tx-danger'}>{(row.unrealised_pnl).toFixed(4)}</span> {row.currency} <p class="tx-10 tx-color-03 mg-b-0">${((row.unrealised_pnl)* get_price(row.currency)).toFixed(2)} USD</p></td>
        </tr>)
    //const portfolio = updatePortfolio()
    return (
        <div class="row">
    <div class="col-lg-12 mg-t-10">
    <div class="card mg-b-10">
      <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
        <div>
          <h3 class="mg-b-5">Wallets</h3>
        </div>
    </div>
    <div class="card-body">
      <div class="table-responsive pd-y-15">
        <table class="table table-dashboard mg-b-0">
          <thead>
            <tr>
              <th class=""></th>
              <th class="">Account</th>
              <th class="text-center">Wallet Bal.</th>
              <th class="text-center">Margin Bal.</th>
              <th class="text-center">Realised PNL</th>
              <th class="text-center">UnRealised PNL</th>
            </tr>
          </thead>
          <tbody class="">
              {/** 
              <tr>
                  <td></td>
                  <td class='tx-color-02 tx-bold'>Portfolio</td>
                  <td class='tx-color-02 tx-rubik tx-bold tx-center'>{portfolio.walletBalance.toFixed(4)} BTC</td>
                  <td class='tx-color-02 tx-rubik tx-bold tx-center'><span class='tx-teal'>{portfolio.marginBalance.toFixed(4)}</span> BTC</td>
                  <td class='tx-color-02 tx-rubik tx-bold tx-center'><span class={portfolio.unrealisedPnl > 0? 'tx-success' : 'tx-danger'}>{portfolio.unrealisedPnl.toFixed(4)}</span> BTC</td>
                  <td class='tx-color-02 tx-rubik tx-bold tx-center'><span class={portfolio.realisedPnl > 0? 'tx-success' : 'tx-danger'}>{portfolio.realisedPnl.toFixed(4)}</span> BTC</td>
              </tr>
              */}
              {bitmexRows}
              {bybitRows}
              {ftxusRows}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  </div>
  </div>
    )

}