import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import Chart from './chart';
export default function Performance() {


    const [transactions, setTransactions] = useState([])
    const getData = () => {
        fetch('/api/protfolio_transactions')
        .then(res => res.json())
        .then(transactions => {
            console.log(transactions)
            setTransactions(f => transactions)
        })
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <>
        <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                <li class="breadcrumb-item"><Link to='/'>Dashboard</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Performance</li>
              </ol>
            </nav>
            <h2 class="mg-b-0 tx-spacing--1">Portfolio Performance</h2>
          </div>
          </div>
          <div class="row row-xs">
          <div class="col-lg-12 mg-t-10">
            <div class="card mg-b-10">
              <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                <div>
                  <h3 class="mg-b-5">Profit & Loss</h3>
                  {transactions.length > 0 &&
                <p class="mg-b-0 tx-rubik">{parseFloat(transactions[transactions.length -1]['total_pnl'].toFixed(2)).toLocaleString('en-US')} USD</p>
                  }
                </div>
              </div>
              <div class="card-body pd-y-30">
                <Chart datakey={'total_pnl'} transactions={transactions}/>
              </div>
            </div>
          </div>

          <div class="col-lg-12 mg-t-10">
            <div class="card mg-b-10">
              <div class="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                <div>
                  <h3 class="mg-b-5">Return on Investment</h3>
                  {transactions.length > 0 &&
                <p class="mg-b-5">{transactions[transactions.length -1]['total_roi'].toFixed(3)} %</p>
                 }
                </div>
                
              </div>
              <div class="card-body pd-y-30">
                <Chart datakey={'total_roi'} transactions={transactions}/>
              </div>
            </div>
          </div>

          <div class="col-lg-12 mg-t-10">
            <div class="card mg-b-10">
              <div class="card-header pd-t-20 d-sm-flex align-items-center justify-content-between bd-b-0 pd-b-0">
                <div>
                  <h3 class="mg-b-5">Total Equity</h3>
                  {transactions.length > 0 &&
                <p class="mg-b-0 tx-rubik">{parseFloat(transactions[transactions.length -1]['equity'].toFixed(2)).toLocaleString('en-US')} USD</p>
                  }
                </div>
              </div>
              <div class="card-body pd-y-30">
                <Chart datakey={'equity'} transactions={transactions}/>
              </div>
            </div>
          </div>

          </div>
        </>)}