import React, {useEffect, useState} from 'react';
import { AreaChart, XAxis,Tooltip, Area, Line, ResponsiveContainer, YAxis, LineChart } from 'recharts'
import {socket, useGlobalState} from '../../config'
import moment from 'moment'
export default function Chart({datakey, transactions}) {

      const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
          if (payload[0] == null) {
            return null
          }

          let dt = new Date(label)
          return (
            <div className="custom-tooltip">
              <h5 class="tx-normal tx-rubik tx-spacing--2 mg-b-0">{(+payload[0].value).toFixed(datakey == 'total_roi'? 3: 2)} {datakey == 'total_roi'? '%': 'USD'}</h5>
              <small className={payload[0].payload.pnl > 0 ? 'tx-success' : 'tx-danger'}>P&L: {(+payload[0].payload.pnl).toFixed(2)} USD </small><br/>
              <small className={payload[0].payload.pnl > 0 ? 'tx-success' : 'tx-danger'}>ROI: {(+payload[0].payload.roi).toFixed(3)} % </small>
              
              <p className="tx-color-03">{moment(dt).format('DD MMM, YY')}</p>
            </div>
          );
        }
      
        return null;
      };
    return (
        <ResponsiveContainer width="100%" height={450}>
        <AreaChart  data={transactions}
          margin={{ top: 10, right: 30, left: 20, bottom: 0 }}>
            <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#0168fa" stopOpacity={0.2}/>
                <stop offset="95%" stopColor="#0168fa" stopOpacity={0}/>
            </linearGradient>
            </defs>
            <XAxis tick={{stroke: '#8392a5', strokeWidth: 0.01}} interval={'preserveStart'} minTickGap={50} axisLine={false} tickLine={false} dataKey="date" tickFormatter={xAxsisFormat} />
            <YAxis tick={{stroke: '#8392a5', strokeWidth: 0.01}} tickLine={false} axisLine={false} tickFormatter={yAxsisFormat}/>
            
            <Tooltip content={<CustomTooltip />}/>
            <Area type="monotone" dataKey={datakey} stroke="#0168fa" fillOpacity={1} fill="url(#colorPv)" />     
        </AreaChart>
        </ResponsiveContainer>)
}

const xAxsisFormat = (ts) => {
    let dt = new Date(ts)
    return moment(dt).format('DD MMM')
  }
  
  const yAxsisFormat = (value) => {
    return (value.toFixed(2))
  }


